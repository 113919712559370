.module__text {
	background-color: #fff;
	float: left;
	width: 100%;
}

.module__text__container {
	display: table;
	margin: 0 auto;
	max-width: 700px;
	padding: 0 30px;
	position: relative;
	width: 100%;
}

.module__text__headlines {
	@extend h1;
	float: left;
	text-align: center;
	text-transform: none;
	width: 100%;
}

.module__text__headline {
	@extend h1;
	margin: 30px auto 0;
}

.module__text__subheadline {
	@extend h3;
	margin-top: 10px;
}

.module__text__text {
	float: left;
	margin-bottom: 20px;
	margin-top: 20px;
	width: 100%;
}

.module__text__button {
	float: left;
	margin-bottom: 50px;
	text-align: center;
	width: 100%;
}

.module__text--secondary {
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;

	.module__text__subheadline {
		margin-top: 20px;
		text-align: left;
	}

	.module__text__button {
		margin-bottom: 20px;
		margin-top: 0;
		text-align: left;
	}
}

.module__text--tertiary {
	.module__text__container {
		padding-bottom: 20px;
		padding-top: 20px;
	}

	p {
		margin: 20px auto;
	}
}

.col-4 .module__text--secondary {
	flex-grow: 1;
}


@media only screen and (max-width: $device-large) {
	.module__text__container {
		padding-left: 25px;
		padding-right: 25px;
	}
}

@media only screen and (max-width: $device-medium) {
	.module__text__container {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media only screen and (max-width: $device-small) {
	.module__text__container {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (max-width: $device-xsmall) {
	.module__text__container {
		padding-left: 10px;
		padding-right: 10px;
	}
}
