.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	width: 1px;

	&:focus {
		background-color: #f1f1f1;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
		clip: auto;
		color: $color-primary;
		display: block;
		font-size: 14px;
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		outline: 0;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 9999;
	}
}
