.content__hero {
	background-color: #fff;
	float: left;
	margin: 10px;
	max-width: 260px;
	position: relative;

	&:last-of-type {
		margin-bottom: 40px;
	}
}

.content__hero__tags {
	color: #000;
	font-size: 15px;
	line-height: 20px;
	margin: 0 10px;
	padding: 20px 0;
}

.content__hero__filters__nav {
	color: $color-primary;
	position: absolute;
	right: 0;
	top: 50px;
	z-index: 2;

	span {
		display: none;
		vertical-align: middle;

		&.content__hero__filters__open--visible {
			background-color: $color-primary;
			border-radius: 100%;
			color: #fff;
			display: inline-block;
			font-size: 10px;
			padding: 5px;
		}
	}
}

.content__hero__filters__open,
.content__hero__filters__close {
	cursor: pointer;
	display: none;
	padding: 10px 0 10px 10px;

	&:before {
		content: "";
	}

	&:after {
		margin-left: 10px;
	}
}

.content__hero__filters__close:after {
	content: "\f14f";
}

.content__hero__filters__open--visible,
.content__hero__filters__close--visible {
	display: inline-block;
}

.content__hero__filters__container {
	background-color: #fff;
	bottom: 0;
	box-shadow: -1px 5px 5px 0 #aaa;
	position: absolute;
	right: -485px;
	top: 100px;
	transition: right .25s ease;
	width: 480px;
	z-index: 1;
}

.content__hero__filters__container--visible {
	right: 0;
}

.content__hero__filters__remove {
	color: $color-link;
}

.content__hero__filters {
	margin: 40px 0 auto 30px;
	overflow-y: scroll;
	padding-top: 20px;

	&:before {
		border-top: 1px solid $color-secondary;
		content: "";
		display: block;
		height: 2px;
		margin-top: -20px;
		position: absolute;
		width: calc(100% - 60px);
	}
}

.content__hero__filters--filtered {
	margin-top: 0;
	max-height: 285px;
}

.content__hero__filters__all {
	height: 100%;
	max-height: 500px;
	overflow-y: scroll;
}

.content__hero__filter {
	@include font-size-and-line-height("p");
	color: $color-secondary;
	display: inline-block;
	font-family: "Montserrat-Light";
	margin: 10px 0;
	width: 100%;
}

.content__hero__filters__remove .icon-rubbish-bin-delete-button,
.content__hero__filter--filtered.icon-close-button:before {
	margin-left: 10px;
}

.content__hero__tag {
	&:after {
		color: #000;
		content: ", ";
		cursor: none;
		pointer-events: none;
	}

	&:last-child:after {
		display: none;
	}
}

.content__hero__container {
	display: flex;
	flex-wrap: wrap;
	float: left;
	justify-content: center;
	width: 100%;
}

.content__hero__image {
	max-width: 285px;
	width: 100%;
}

.content__hero__name {
	margin-bottom: 10px;
	overflow: hidden;
	padding: 5px 0;
	text-align: center;
}

.content__hero__container__hero {
	flex: 0 0 calc(25% - 20px);
	margin: 10px;
	max-width: 285px;
}

.content__hero--detail {
	margin: 0;
	max-width: none;
	width: 100%;

	.content__hero__image {
		display: block;
		margin: 0 auto;
	}

	.content__hero__name {
		display: block;
		width: 100%;
	}

	.content__hero__container__info__military {
		color: $color-secondary;
		margin-bottom: 30px;
		max-width: 800px;
		text-align: center;
	}

	.content__hero__container__info__additional {
		background-color: #eee;
		display: block;
		padding: 30px 0 40px;
		text-align: center;
		width: 100%;
	}

	.content__hero__container__info__additional__detail {
		color: $color-secondary;
		display: inline-block;
		margin: 0 10px;
		text-align: left;
		vertical-align: top;
		width: 35%;

		.content__hero__cause-of-death:first-child {
			display: none;
		}

		span {
			color: #000;
			font-weight: bold;
		}
	}

	.content__hero__age,
	.content__hero__branch,
	.content__hero__rank {
		display: inline;
	}

	.content__hero__age {
		+ .content__hero__branch:before,
		+ .content__hero__rank:before {
			content: " • ";
		}
	}

	.content__hero__branch {
		+ .content__hero__rank:before {
			content: " • ";
		}
	}

	.content__hero__unit {
		+ .content__hero__station:before,
		+ .content__hero__campaign:before {
			content: " • ";
		}
	}

	.content__hero__station {
		+ .content__hero__campaign:before {
			content: " • ";
		}
	}
}

.content__hero__container__more {
	display: flex;
	flex-wrap: wrap;
	float: left;
	justify-content: center;
	width: 100%;

	.content__hero__image {
		min-height: auto;
		min-width: auto;
	}
}

.content__hero__header {
	clear: left;
	float: left;
	margin-top: 20px;
	padding: 20px 0;
	text-align: center;
	width: 100%;
}

.content__hero__button {
	clear: left;
	float: left;
	margin: 20px auto 50px;
	text-align: center;
	width: 100%;
}


@media only screen and (max-width: $device-large) {
	.content__hero__filters__open,
	.content__hero__filters__close {
		padding-right: 0;
	}

	.content__hero--detail .content__hero__container__info__additional__detail {
		margin: 0;
		padding-left: 25px;
		padding-right: 25px;
		width: 100%;
	}

	.content__hero__container__hero {
		flex: 0 0 calc(33.3333% - 20px);
		max-width: 320px;
	}

	.content__hero__image {
		max-width: 320px;
	}

	.content__hero--detail .content__hero__image {
		max-width: 285px;
	}
}

@media only screen and (max-width: $device-medium) {
	.content__hero__tags {
		font-size: 14px;
		line-height: 19px;
	}

	.content__hero__filters__all {
		max-height: 300px;
	}

	.content__hero--detail .content__hero__container__info__additional__detail {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media only screen and (max-width: $device-small) {
	.content__hero__filters__nav {
		top: 60px;
	}

	.content__hero__filters__container {
		top: 100px;
	}

	.content__hero__tags {
		font-size: 13px;
		line-height: 18px;
	}

	.content__hero--detail .content__hero__container__info__additional__detail {
		padding-left: 15px;
		padding-right: 15px;
	}

	.content__hero__container__hero {
		flex: 0 0 calc(50% - 20px);
		max-width: 389px;
	}

	.content__hero__image {
		max-width: 389px;
	}

	.content__hero__filters__all {
		max-height: 240px;
	}
}

@media only screen and (max-width: $device-xsmall) {
	.content__hero__filters__container {
		width: 100%;
	}

	.content__hero__filters {
		margin-left: 10px;

		&:before {
			width: calc(100% - 20px);
		}
	}

	.content__hero__filters__close,
	.content__hero__filters__open {
		padding-left: 0;
	}

	.content__hero--detail .content__hero__container__info__additional__detail {
		padding-left: 10px;
		padding-right: 10px;
	}
}
