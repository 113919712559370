@import "accessibility";
@import "fonts";

::-webkit-scrollbar {
	height: 10px;
	width: 10px;
}

::-webkit-scrollbar-button {
	height: 10px;
	width: 10px;
}

::-webkit-scrollbar-thumb {
	background-color: $color-primary;
	border: 0 none #fff;
	border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
	background-color: $color-hover;
}

::-webkit-scrollbar-thumb:active {
	background-color: $color-active;
}

::-webkit-scrollbar-track {
	background-color: #fff;
	border: 0;
	border-radius: 0;
}

::-webkit-scrollbar-track:hover {
	background-color: #f1f1f1;
}

::-webkit-scrollbar-track:active {
	background-color: #f1f1f1;
}

::-webkit-scrollbar-corner {
	background-color: transparent;
}

html {
	box-sizing: border-box;
	font-family: "Montserrat-Regular", serif;
	-webkit-font-smoothing: subpixel-antialiased;
	min-width: 320px;
	// scss-lint:disable VendorPrefix
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	// scss-lint:enable VendorPrefix
	user-select: none;
}

html,
body {
	height: 100%;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.body--locked {
	overflow: hidden;
}

%h1 {
	@include font-size-and-line-height("h1");
	color: #265566;
	font-family: "Montserrat-Light";
	font-weight: normal;
	text-transform: uppercase;
}

%h2 {
	@include font-size-and-line-height("h2");
	color: #466fb6;
	font-family: "Montserrat-Regular";
	font-weight: bold;
}

%h3 {
	@include font-size-and-line-height("h3");
	color: #a92a5b;
	font-family: "Montserrat-Light";
	font-weight: normal;
}

%h4 {
	@include font-size-and-line-height("h4");
	color: #466eb6;
	font-family: "Montserrat-Light";
	font-weight: normal;
	text-transform: uppercase;
}

h1 {
	@extend %h1;
}

h2 {
	@extend %h2;
}

h3 {
	@extend %h3;
}

h4,
h5,
h6 {
	@extend %h4;
}

a {
	color: $color-link;
	outline: none;
	text-decoration: none;
	transition: color .25s ease;

	&:link {
		color: $color-link;
	}

	&:hover {
		color: $color-hover;
	}

	&:active {
		color: $color-active;
	}
}

p {
	@include font-size-and-line-height("p");
	color: $color-secondary;
	font-family: "Montserrat-Light";
	margin: 0;

	& + & {
		margin-top: 10px;
	}
}

ol,
ul {
	@include font-size-and-line-height("p");
	font-family: "Montserrat-Light";
	margin: 10px 0 20px;
}

%button {
	@include font-size-and-line-height("p");
	background-color: $color-link;
	border: 3px solid $color-link;
	border-radius: 5px;
	box-sizing: border-box;
	color: #fff;
	font-family: "Montserrat-Light";
	font-size: 18px;
	line-height: 18px;
	padding: 12px 22px;
	transition: border-color .25s ease, color .25s ease, background .25s ease;

	&:link {
		background-color: $color-link;
		border-color: $color-link;
	}

	&:hover {
		background-color: $color-hover;
		border-color: $color-hover;
		color: #fff;
	}

	&:active {
		background-color: $color-active;
		border-color: $color-active;
		color: #fff;
	}
}

.button__primary {
	@extend %button;
}

.mobile {
	display: none;
}

.site {
	background-color: #fff;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.site__main {
	min-height: 100%;

	&.site__wrapper {
		display: inline-block;
		margin-bottom: 60px;
	}
}

.site__content {
	clear: left;
	float: left;
	margin: 0 auto;
	min-height: 400px;
	text-align: center;
	width: 100%;
}

.site__content--masked:before {
	background-color: rgba(0, 0, 0, .85);
	content: "";
	height: 10000px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
}

.site__wrapper {
	margin: 0 auto;
	max-width: 1200px;
	padding: 0 30px;
	position: relative;
	text-align: left;
	width: 100%;
}

.site__wrapper__helper {
	min-height: 780px;
	overflow: hidden;
	position: relative;
}

.row {
	background-color: #fff;
	clear: both;
	display: flex;
	flex-wrap: wrap;
}

.col,
.col-3,
.col-4,
.col-6,
.col-8,
.col-9,
.col-12 {
	display: flex;
	flex-flow: row wrap;
	max-width: 100%;
	position: relative;
	width: 100%;
}

.col {
	flex-basis: 0%;
	flex-grow: 1;
	max-width: 100%;
}

.col-3 {
	flex: 1 0 25%;
	max-width: 25%;
}

.col-4 {
	flex: 1 0 33.3333%;
	max-width: 33.3333%;
}

.col-6 {
	flex: 1 0 50%;
	max-width: 50%;
}

.col-8 {
	flex: 1 0 66.6666%;
	max-width: 66.6666%;
}

.col-9 {
	flex: 1 0 75%;
	max-width: 75%;
}

.col-12 {
	flex: 1 0 100%;
	max-width: 100%;
}

.navigation {
	background-color: #fff;
	float: left;
	padding: 30px 0 0;
	text-align: center;
	width: 100%;

	.page-numbers {
		.icon {
			font-size: 12px;
			line-height: 22px;
		}

		&.next,
		&.prev {
			margin: 0 15px;
		}
	}

	.post__navigation__previous,
	.post__navigation__next {
		margin: 0 15px;
	}

	.post__navigation__back {
		margin-top: 10px;
	}
}

.kaleidoscope {
	background: url($image-path + "/kaleidoscope.png") repeat-x;
	height: 8px;
	left: 0;
	position: absolute;
	width: 100%;
}



// scss-lint:disable ImportantRule
.page-id-8 .site__main {
	&.site__wrapper {
		margin-bottom: 0 !important;
	}
}
// scss-lint:enable ImportantRule



@media only screen and (max-width: $device-large) {
	%button {
		@include font-size-and-line-height("p", $device-large);
		line-height: 15px;
	}

	h1 {
		@include font-size-and-line-height("h1", $device-large);
	}

	h2 {
		@include font-size-and-line-height("h2", $device-large);
	}

	h3 {
		@include font-size-and-line-height("h3", $device-large);
	}

	h4,
	h5,
	h6 {
		@include font-size-and-line-height("h4", $device-large);
	}

	p,
	.page-numbers,
	.post__navigation__link {
		@include font-size-and-line-height("p", $device-large);
	}

	ol,
	ul {
		font-size: inherit;
		line-height: inherit;
	}

	.mobile {
		display: block;
	}

	.site__wrapper {
		padding: 0 25px;
	}

	.site__main.site__wrapper {
		margin-bottom: 55px;
	}
}

@media only screen and (max-width: $device-medium) {
	%button {
		@include font-size-and-line-height("p", $device-medium);
		line-height: 14px;
	}

	h1 {
		@include font-size-and-line-height("h1", $device-medium);
	}

	h2 {
		@include font-size-and-line-height("h2", $device-medium);
	}

	h3 {
		@include font-size-and-line-height("h3", $device-medium);
	}

	h4,
	h5,
	h6 {
		@include font-size-and-line-height("h4", $device-medium);
	}

	p,
	.page-numbers,
	.post__navigation__link {
		@include font-size-and-line-height("p", $device-medium);
	}

	.site__wrapper {
		padding: 0 20px;
	}

	.site__main.site__wrapper {
		margin-bottom: 50px;
	}
}

@media only screen and (max-width: $device-small) {
	%button {
		@include font-size-and-line-height("p", $device-xsmall);
		line-height: 13px;
	}

	h1 {
		@include font-size-and-line-height("h1", $device-xsmall);
	}

	h2 {
		@include font-size-and-line-height("h2", $device-xsmall);
	}

	h3 {
		@include font-size-and-line-height("h3", $device-xsmall);
	}

	h4,
	h5,
	h6 {
		@include font-size-and-line-height("h4", $device-xsmall);
	}

	p,
	.page-numbers,
	.post__navigation__link {
		@include font-size-and-line-height("p", $device-xsmall);
	}

	.site__wrapper {
		padding: 0 15px;
	}

	.site__main.site__wrapper {
		margin-bottom: 45px;
	}
}

@media only screen and (max-width: $device-small) {
	.col,
	.col-3,
	.col-4,
	.col-6,
	.col-8,
	.col-9,
	.col-12 {
		flex: 1 0 100%;
		max-width: 100%;
	}

	.row .col-6 {
		order: 2;
	}

	.row .col-alt {
		order: 1;
	}

	.site__main.site__wrapper {
		margin-bottom: 45px;
	}
}

@media only screen and (max-width: $device-xsmall) {
	%button {
		@include font-size-and-line-height("p", $device-xsmall);
		line-height: 13px;
	}

	h1 {
		@include font-size-and-line-height("h1", $device-xsmall);
	}

	h2 {
		@include font-size-and-line-height("h2", $device-xsmall);
	}

	h3 {
		@include font-size-and-line-height("h3", $device-xsmall);
	}

	h4,
	h5,
	h6 {
		@include font-size-and-line-height("h4", $device-xsmall);
	}

	p,
	.page-numbers,
	.post__navigation__link {
		@include font-size-and-line-height("p", $device-xsmall);
	}

	.site__wrapper {
		padding: 0 10px;
	}

	.site__main.site__wrapper {
		margin-bottom: 40px;
	}
}
