.page__search__results {
	color: $color-secondary;
	margin-bottom: 40px;
	text-align: center;

	h3 {
		margin: 23px 0 29px;
	}

	ul {
		margin: 0 30px;
		text-align: left;
	}

	li {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			display: block;
			margin: 10px 0;
		}
	}

	.site__search--alternative {
		form {
			text-align: center;
		}

		// scss-lint:disable PropertySortOrder
		.site__search__input--alternative {
			border: 1px solid $color-secondary;
			border-radius: 5px;
			border-right: 0;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			color: $color-secondary;
			font-size: 18px;
			height: 48px;
			margin-right: -8px;
			min-width: 280px;
			padding: 0 20px;
			position: relative;
			z-index: 2;
		}
		// scss-lint:disable PropertySortOrder

		.button__primary {
			position: relative;
			z-index: 1;
		}
	}
}


@media only screen and (max-width: $device-large) {
	.page__search__results ul {
		margin: 0 25px;
	}

	.page__search__results .site__search--alternative .site__search__input--alternative {
		border-radius: 5px;
		border-right-color: #898989;
		border-right-style: solid;
		border-right-width: 1px;
		height: 45px;
	}

	.site__search__button.button__primary {
		display: block;
		margin: 4px auto 0;
	}
}

@media only screen and (max-width: $device-medium) {
	.page__search__results ul {
		margin: 0 20px;
	}

	.page__search__results .site__search--alternative .site__search__input--alternative {
		height: 44px;
	}
}

@media only screen and (max-width: $device-small) {
	.page__search__results ul {
		margin: 0 15px;
	}

}

@media only screen and (max-width: $device-xsmall) {
	.page__search__results ul {
		margin: 0 10px;
	}

	.page__search__results .site__search--alternative .site__search__input--alternative {
		border-radius: 5px;
		border-right: 1px solid $color-secondary;
		margin: 0 auto;
	}
}
