.social__links {
	float: left;
	list-style-type: none;
	margin-top: 10px;
	width: 100%;

	li {
		display: inline-block;
		font-size: 24px;
		margin: 0 5px;
		position: relative;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&.icon-email {
			// color: #333;
			color: $color-primary;
		}

		&.icon-facebook {
			// color: #3b5998;
			color: $color-primary;
		}

		&.icon-instagram {
			// color: #cd3878;
			color: $color-primary;
		}

		&.icon-twitter {
			// color: #29a8e0;
			color: $color-primary;
		}

		&.icon-linkedin {
			// color: #0077b5;
			color: $color-primary;
		}

		a {
			display: inline-block;
			font-size: 0;
			height: 24px;
			left: 0;
			position: absolute;
			top: 2px;
			width: 24px;
		}
	}
}
