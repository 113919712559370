.content__category-event {
	background-color: #fff;
	border-bottom: 1px solid #f2f2f2;
	float: left;
	min-height: 400px;
	position: relative;
	width: 100%;

	&:last-of-type {
		border-bottom: 0;
	}

	.social__links {
		text-align: center;
	}

	.module__map {
		margin-right: 30px;
		width: calc(50% - 30px);
	}

	.module__map__container {
		float: left;
		height: 400px;
		position: relative;
		width: 100%;
	}

	.module__map__container__map {
		height: 188px;
		width: 100%;
	}

	.module__map__container__info {
		background-color: #fff;
		height: 212px;
		margin: 0;
		padding: 30px;
		position: relative;
		width: 100%;
	}

	.module__map__container__info__text {
		width: 100%;
	}

	.module__map__container__info__text--title {
		margin-bottom: 20px;
		width: 100%;
	}

	.module__map__container__info__address {
		float: left;
		width: 50%;
	}

	.module__map__container__info__phone,
	.module__map__container__info__email {
		color: $color-primary;
	}
}

.content__category-event__container {
	float: left;
	margin: 0 30px;
	width: calc(50% - 60px);
}

.content__category-event__title {
	float: left;
	margin-top: 30px;
	width: 100%;
}

.content__category-event__date {
	float: left;
	margin-top: 10px;
	width: 100%;
}

.content__category-news__source {
	float: left;
	width: 100%;
}

.content__category-event__text {
	float: left;
	margin-bottom: 20px;
	margin-top: 20px;
}

.content__category-event__button {
	clear: left;
	float: left;
	margin-bottom: 20px;
	text-align: left;
}

.content__category-event__image {
	float: none;
	margin-bottom: 30px;
	margin-right: 30px;
	margin-top: 30px;
	min-height: 340px;
	width: 100%;
}

.content__category-event--featured {
	background-color: #eee;
}

.content__category-event--detail {
	.content__category-event__title {
		text-align: center;
	}

	.content__category-event__date__time {
		text-align: center;
	}

	.content__category-event__date {
		display: inline-block;
		float: none;
		margin: 0;
		width: auto;

		+ .content__category-event__time:before {
			content: " • ";
		}
	}

	.content__category-event__time {
		display: inline-block;
		margin: 0;

		span + span:before {
			content: " • ";
		}
	}

	.content__category-event__image {
		clear: left;
		float: none;
		width: 100%;
	}
}


@media only screen and (max-width: $device-large) {
	.content__category-event {
		min-height: 390px;
	}

	.content__category-event .module__map {
		width: 100%;
	}

	.content__category-event__container {
		@include container-spacing($device-large);
		margin-bottom: 0;
		margin-top: 0;
		min-height: 390px;
		width: calc(66% - 50px);
	}

	.content__category-event__button {
		margin-top: 0;
	}

	.content__category-event__image {
		margin-right: 25px;
		margin-top: 25px;
		width: calc(34% - 25px);
	}

	.content__category-event .module__map__container__info {
		padding: 25px;
	}
}

@media only screen and (max-width: $device-medium) {
	.content__category-event {
		min-height: 380px;
	}

	.content__category-event__container {
		@include container-spacing($device-medium);
		margin-bottom: 0;
		margin-top: 0;
		min-height: 380px;
		width: calc(66% - 40px);
	}

	.content__category-event__text {
		margin-top: 15px;
	}

	.content__category-event__image {
		margin-right: 20px;
		margin-top: 20px;
		width: calc(34% - 20px);
	}

	.content__category-event .module__map__container__info {
		padding: 20px;
	}
}

@media only screen and (max-width: $device-small) {
	.content__category-event__container {
		@include container-spacing($device-small);
	}

	.content__category-event .module__map__container__info {
		padding: 15px;
	}
}

@media only screen and (max-width: $device-xsmall) {
	.content__category-event {
		min-height: 0;
	}

	.content__category-event__container {
		@include container-spacing($device-xsmall);
		margin-bottom: 0;
		margin-top: 0;
		min-height: 0;
		width: calc(100% - 30px);
	}

	.content__category-event__title {
		margin-top: 20px;
	}

	.content__category-event__image {
		float: none;
		height: 100%;
		margin: 15px auto 0;
		min-height: 100px;
		width: 100%;
	}

	.content__category-event .module__map__container__info {
		padding: 10px;
	}
}
