.site__certifications {
	display: inline-block;

	li {
		display: inline-block;
		height: 75px;
		margin-top: 15px;
		vertical-align: middle;
	}

	a {
		display: block;
		height: 75px;
		margin: 0 20px 0 0;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
	}

	.logo-city-of-boston {
		background: url($image-path + "/logo-city-of-boston.png") no-repeat;
		background-size: contain;
		width: 100px;
	}

	.logo-boston-main-streets-foundation {
		background: url($image-path + "/logo-boston-main-streets-foundation.png") no-repeat;
		background-size: contain;
		width: 169px;
	}
}


@media only screen and (max-width: $device-large) {
	.site__certifications {
		display: table;
		margin: 0 auto;
	}
}
