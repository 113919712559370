.site__footer {
	background-color: #fff;
	clear: left;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	width: 100%;

	&:before {
		@extend .kaleidoscope;
		content: "";
		top: 0;
	}
}

@import "site-certifications";
@import "site-social-links";
@import "site-navigation";
@import "site-legal-and-terms";
