.site__navigation {
	background-color: #fff;
	height: 138px;
	position: relative;
	width: 100%;

	&:after {
		@extend .kaleidoscope;
		bottom: 0;
		content: "";
		z-index: 2;
	}
}

.site__navigation--fixed {
	position: fixed;
	top: 0;
}

.site__navigation__menu__container {
	color: $color-link;
	cursor: pointer;
	float: right;
	position: absolute;
	right: 25px;
	top: 12px;
	z-index: 2;

	&:link,
	&:visited {
		color: #fff;
	}

	span:after {
		border-bottom: solid 3px $color-link;
		content: "";
		display: block;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		right: 0;
		top: 23px;
		transform: scaleX(0);
		transition: transform .25s ease-out;
		width: 50%;
	}

	&:hover span, {
		&:after {
			transform: scaleX(1);
		}
	}

	.site__navigation__menu__close--visible {
		&:after {
			transform: scaleX(1);
			width: 50%;
		}
	}

	&:active .site__navigation__menu__close--visible {
		&:after {
			transform: scaleX(1);
			transition: width .25s ease-out;
			width: 100%;
		}
	}
}

.site__navigation__menu {
	display: block;
}

.site__navigation__menu--hidden {
	display: none;
}

.site__navigation__menu__close {
	display: none;
}

.site__navigation__menu__close--visible {
	display: block;
}

// scss-lint:disable ImportantRule
.site__navigation__ancillary--visible {
	display: block !important;
}
// scss-lint:enable ImportantRule

.site__navigation__primary {
	background-color: #fff;
	height: 88px;
	margin: 0;
	position: relative;
	width: 100%;

	&:before,
	&:after {
		background-color: #fff;
		content: "";
		height: inherit;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&:before {
		left: -100%;
	}

	&:after {
		left: 100%;
	}

	li {
		display: inline-block;
		float: left;
		line-height: 82px;
		margin: 0 20px;
		padding: 0;
		vertical-align: middle;

		&.current-menu-item > a:after {
			transform: scaleX(1);
			transition: width .25s ease-out;
			width: 100%;
		}

		&.current-page-parent > a,
		&.current-page-ancestor > a {
			&:after {
				transform: scaleX(1);
			}
		}
	}

	a {
		display: inline-block;
		outline: none;
		position: relative;
		text-decoration: none;
		transition: color .25s ease;

		&:after {
			border-bottom: solid 3px $color-link;
			content: "";
			display: block;
			left: 0;
			margin-left: auto;
			margin-right: auto;
			position: absolute;
			right: 0;
			top: 58px;
			transform: scaleX(0);
			transition: transform .25s ease-out;
			width: 50%;
		}

		&:link {
			color: $color-link;
		}

		&:visited {
			color: $color-link;
		}

		&:hover {
			&:after {
				transform: scaleX(1);
			}
		}

		&:active {
			color: $color-link;

			&:after {
				transform: scaleX(1);
				transition: width .25s ease-out;
				width: 100%;
			}
		}
	}

	.menu-item-has-children:hover {
		> a:after {
			transform: scaleX(1);
		}

		.sub-menu-wrapper:before,
		.sub-menu {
			height: 50px;
		}
	}

	.logo__mod {
		color: transparent;
		font-size: 0;
		height: 40px;
		line-height: 0;
		margin: 20px 20px 0 0;
		width: 137px;

		a {
			background-image: url($image-path + "/logo-mod.png");
			background-repeat: no-repeat;
			background-size: contain;
			display: block;
			height: 40px;

			&:after {
				border: 0;
			}
		}
	}

	.sub-menu-wrapper {
		left: 0;
		width: 100%;

		&:before {
			background-color: rgba(255, 255, 255, .75);
			content: "";
			height: 0;
			left: 0;
			position: absolute;
			transition: height .25s ease;
			width: 100%;
			z-index: 1;
		}
	}

	.sub-menu {
		height: 0;
		margin: 0;
		overflow: hidden;
		position: absolute;
		transition: height .25s ease;
		z-index: 2;

		li {
			display: inline-block;
			float: left;
			line-height: 50px;
			margin: 0 20px;
			padding: 0;
			text-align: center;
			vertical-align: middle;

			&:first-child {
				margin-left: 0;
			}
		}

		a {
			&:after {
				top: 38px;
			}

			&:link {
				color: $color-link;
			}

			&:visited {
				color: $color-link;
			}

			&:hover {
				color: $color-hover;
			}

			&:active {
				color: $color-link;
			}
		}
	}

	.icon-searching-magnifying-glass {
		color: $color-link;
		cursor: pointer;
		font-size: 22px;
		margin: 0 2px 0 0;
		position: absolute;
		right: 0;
		text-align: right;
		width: 24px;

		a {
			display: block;
			height: 25px;
			overflow: hidden;
			position: absolute;
			text-indent: 100%;
			top: 30px;
			white-space: nowrap;
			width: 25px;

			&:after {
				border: 0;
			}
		}
	}

	.icon-searching-magnifying-glass--hidden {
		display: none;
	}

	.icon-close-button {
		color: $color-primary;
		cursor: pointer;
		display: none;
		font-size: 18px;
		margin: 0 0 0 20px;
		overflow: hidden;
		position: absolute;
		right: 0;
		width: 20px;
		z-index: 2;

		a {
			text-indent: -9999px;
		}
	}

	.icon-close-button--visible {
		display: block;
	}
}

.site__navigation__ancillary {
	background-color: #edf0f1;
	height: 50px;
	margin: 0;
	position: relative;
	width: 100%;

	&:before,
	&:after {
		background-color: #edf0f1;
		content: "";
		height: inherit;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&:before {
		left: -100%;
	}

	&:after {
		left: 100%;
	}

	li {
		display: inline-block;
		float: right;
		line-height: 50px;
		margin: 0 20px;
		padding: 0;
		vertical-align: middle;

		&:first-child {
			margin-right: 0;
		}

		a {
			color: $color-link;
		}
	}
}


@media only screen and (max-width: 1200px) {
	.site__navigation__primary li,
	.site__navigation__ancillary li,
	.site__navigation__more li {
		margin: 0 13px;
	}

	.site__navigation__primary .logo__mod {
		margin-left: 0;
		margin-top: 20px;
	}
}

@media only screen and (max-width: $device-large) {
	.site__navigation {
		background-color: #fff;
		height: auto;
	}

	.site__navigation__menu__container {
		z-index: 3;
	}

	.site__navigation__helper {
		max-height: 50px;
		overflow: hidden;
		transition: max-height .25s ease-out;
	}

	.site__navigation__ancillary {
		background-color: #fff;
		display: none;
		height: 100%;
		max-height: 50px;
		position: absolute;
		right: 110px;
		width: auto;
		z-index: 2;

		&:before,
		&:after {
			background-color: inherit;
		}

		li {
			font-family: "Montserrat-Regular";
			line-height: 44px;
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	.site__navigation__primary {
		background: transparent;
		border-bottom: 0;
		float: left;
		height: auto;

		li {
			clear: left;
			display: block;
			float: left;
			line-height: 50px;
			margin: 0;

			a {
				background-position: 0 6px;
				background-size: contain;
				margin-left: 10px;
				margin-right: 10px;

				&:after {
					top: 38px;
				}
			}
		}

		.logo__mod {
			display: block;
			height: 42px;
			margin-top: 0;
			width: 112px;

			a {
				height: 42px;
				margin: 0;
				z-index: 3;
			}
		}

		.icon-searching-magnifying-glass {
			display: none;
		}

		.icon-close-button {
			display: none;
		}

		.sub-menu-wrapper {
			&:before {
				display: none;
			}
		}

		.menu-item-has-children:hover .sub-menu,
		.menu-item-has-children:hover .sub-menu-wrapper:before {
			height: 0;
		}

		.sub-menu {
			margin-top: -9px;
			overflow: visible;
			position: relative;

			// scss-lint:disable ImportantRule
			li {
				display: inline-block;
				float: left;
				line-height: 32px;
				margin: 0;
				text-align: left;

				&:first-child {
					margin-left: 15px !important;
				}
			}
			// scss-lint:enable ImportantRule

			// scss-lint:disable ImportantRule
			a {
				@include font-size-and-line-height("p", $device-medium);

				color: #fff !important;
				margin-left: 20px;
				margin-right: 20px;

				&:after {
					top: 23px;
				}

				&:link {
					color: #fff;
				}
			}
			// scss-lint:enable ImportantRule
		}
	}
}

@media only screen and (max-width: $device-medium) {
	.site__navigation__menu__container {
		right: 20px;
	}

	.site__navigation__ancillary {
		right: 105px;
	}
}

@media only screen and (max-width: $device-small) {
	.site__navigation__menu__container {
		right: 15px;
	}
}

@media only screen and (max-width: $device-xsmall) {
	.site__navigation__menu__container {
		right: 10px;
	}

	.site__navigation__ancillary {
		right: 95px;
	}
}

@media only screen and (max-width: 380px) {
	.site__navigation__ancillary {
		right: 55px;

		// scss-lint:disable ImportantRule
		li {
			margin-left: 5px !important;
			margin-right: 5px !important;
		}
		// scss-lint:enable ImportantRule
	}
}
