.site__search {
	background-color: #fff;
	height: 87px;
	overflow: hidden;
	position: absolute;
	right: 98px;
	top: 50px;
	width: 0;
	z-index: -1;
}

.site__search--visible {
	width: calc(100% - 266px);
	z-index: 1;
}

.site__search__form {
	height: 100%;

	.site__search__input {
		background-color: transparent;
		height: 50px;
		margin-left: 20px;
		margin-top: 15px;
		padding: 0 20px;
		position: absolute;
		width: calc(100% - 155px);
	}

	// scss-lint:disable VendorPrefix
	.site__search__input::-webkit-input-placeholder {
		color: $color-primary;
		font-family: "Montserrat-Light";
	}

	.site__search__input::-moz-placeholder {
		color: $color-primary;
		font-family: "Montserrat-Light";
	}

	.site__search__input:-ms-input-placeholder {
		color: $color-primary;
		font-family: "Montserrat-Light";
	}
	// scss-lint:enable VendorPrefix

	.site__search__button {
		margin-top: 17px;
		max-width: 115px;
		position: absolute;
		right: 10px;
		transition: background .25s ease-out;
		width: 115px;
	}
}


@media only screen and (max-width: $device-large) {
	.site__search {
		height: 60px;
		position: relative;
		right: auto;
		top: auto;
		width: 100%;
		z-index: 1;
	}

	.site__search__form {
		background-color: #fff;

		.site__search__input {
			@include font-size-and-line-height("p", $device-large);
			color: $color-link;
			height: 50px;
			margin-left: 0;
			margin-top: 0;
			padding: 0 10px;
			width: calc(100% - 130px);
		}
	}

	// scss-lint:disable ImportantRule
	// scss-lint:disable VendorPrefix
	.site__search__input::-webkit-input-placeholder {
		color: $color-link !important;
	}

	.site__search__input::-moz-placeholder {
		color: $color-link !important;
	}

	.site__search__input:-ms-input-placeholder {
		color: $color-link !important;
	}
	// scss-lint:enable ImportantRule
	// scss-lint:enable VendorPrefix

	.site__search__form .site__search__button {
		background-color: $color-primary;
		border: 0;
		margin: 4px 15px 0 0;
		right: 0;
	}
}

@media only screen and (max-width: $device-medium) {
	.site__search--visible {
		width: calc(100% - 144px);
	}

	.site__search__form {

		.site__search__input {
			width: calc(100% - 100px);
		}


		.site__search__button {
			width: 100px;
		}

	}

}
