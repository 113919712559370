.swiper-container {
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	position: relative;
}

.swiper-container-no-flexbox .swiper-slide {
	float: left;
}

.swiper-wrapper {
	box-sizing: content-box;
	display: flex;
	height: 100%;
	position: relative;
	transition-property: transform;
	width: 100%;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
	flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
	margin: 0 auto;
	transition-timing-function: ease-out;
}

.swiper-slide {
	flex-shrink: 0;
	height: 100%;
	position: relative;
	width: 100%;
}

.swiper-wp8-horizontal {
	touch-action: pan-y;
}

.swiper-wp8-vertical {
	touch-action: pan-x;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
	transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
	pointer-events: none;
	transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}

.swiper-zoom-container {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	text-align: center;
	width: 100%;
}

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
	max-height: 100%;
	max-width: 100%;
	object-fit: contain;
}
