.content__category-news {
	background-color: #fff;
	border-bottom: 1px solid #f2f2f2;
	float: left;
	padding-bottom: 20px;
	padding-top: 20px;
	position: relative;
	width: 100%;

	&:first-of-type {
		padding: 20px 0;
	}

	&:last-of-type {
		border-bottom: 0;
	}
}

.content__category-news__container {
	float: left;
	margin: 0 30px;
	width: calc(50% - 60px);
}

.content__category-news__date {
	margin-top: 10px;
}

.content__category-news__date,
.content__category-news__author,
.content__category-news__tags {
	float: left;
	width: 100%;
}

.content__category-news__author span,
.content__category-news__tags span {
	text-transform: uppercase;
}

.content__category-news__title {
	float: left;
	margin-top: 30px;
	width: 100%;
}

.content__category-news__text {
	float: left;
	margin-bottom: 20px;
	margin-top: 20px;
}

.content__category-news__button {
	clear: left;
	float: left;
	text-align: left;
}

.content__category-news__image {
	float: right;
	margin-right: 30px;
	min-height: 340px;
	width: calc(50% - 30px);
}

.content__category-news--featured {
	background-color: #eee;
}


@media only screen and (max-width: $device-large) {
	.content__category-news {
		min-height: 0;

		&:first-of-type {
			padding-top: 0;
		}
	}

	.content__category-news__container {
		@include container-spacing($device-large);
		margin-bottom: 0;
		margin-top: 0;
		width: calc(100% - 50px);
	}

	.content__category-news__button {
		margin-top: 0;
	}

	.content__category-news__image {
		float: left;
		width: 100%;
	}
}

@media only screen and (max-width: $device-medium) {
	.content__category-news__container {
		@include container-spacing($device-medium);
		margin-bottom: 0;
		margin-top: 0;
		width: calc(100% - 40px);
	}

	.content__category-news__text {
		margin-top: 15px;
	}
}

@media only screen and (max-width: $device-small) {
	.content__category-news__container {
		@include container-spacing($device-small);
		margin-bottom: 0;
		margin-top: 0;

		width: calc(100% - 30px);
	}
}

@media only screen and (max-width: $device-xsmall) {
	.content__category-news__container {
		@include container-spacing($device-xsmall);
		margin-bottom: 0;
		margin-top: 0;
		width: calc(100% - 20px);
	}

	.content__category-news__title {
		margin-top: 20px;
	}
}
