.site__alert {
	background-color: $color-primary;
	background-image: repeating-linear-gradient(-45deg, transparent, transparent 10px, #e84336 12px);
	display: none;
	height: 80px;
}

.site__alert--visible {
	display: block;
}

.site__alert__text {
	color: #fff;
	line-height: 80px;
	text-align: center;
}


@media only screen and (max-width: $device-large) {
	.site__alert {
		background-color: #e84336;
		height: 50px;
	}

	.site__alert__text {
		line-height: 50px;
	}
}

@media only screen and (max-width: $device-medium) {
	.site__alert {
		line-height: 50px;
	}
}
