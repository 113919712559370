.site__social__media {
	display: inline-block;
	float: right;

	ul {
		margin: 10px 20px 0;
	}

	li {
		display: inline-block;
		font-size: 24px;
		margin: 42px 5px 0;
		position: relative;

		&.icon-facebook {
			// color: #3b5998;
			color: $color-primary;
		}

		&.icon-twitter {
			// color: #29a8e0;
			color: $color-primary;
		}

		&.icon-instagram {
			// color: #cd3878;
			color: $color-primary;
		}
	}

	a {
		color: transparent;
		display: inline-block;
		font-size: 0;
		height: 24px;
		left: 0;
		line-height: 0;
		position: absolute;
		width: 24px;
	}
}

.logo-boston-main-streets-foundation a {
	cursor: default;
	pointer-events: none;
}


@media only screen and (max-width: $device-large) {
	.site__social__media {
		display: table;
		float: none;
		margin: 0 auto;
	}

	.site__social__media ul {
		margin: 0;
	}

	.site__social__media li {
		line-height: 24px;
		margin-top: 0;
	}
}
