.module__feature {
	float: left;
	width: 100%;
}

.module__feature__header {
	background-color: #fff;
	float: left;
	padding: 20px 0;
	text-align: center;
	width: 100%;
}

.module__feature__container {
	background-color: #fff;
	float: left;
	width: 100%;
}

.module__feature__container__message {
	margin-bottom: 20px;
	text-align: center;

	p {
		margin-bottom: 20px;
	}
}

.module__feature__container__event {
	border-bottom: 1px solid #f2f2f2;
	float: right;
	margin: 0 30px;
	padding: 15px 0;
	position: relative;

	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4) {
		background-color: #fff;
		clear: right;
		margin: 0 20px 0 0;
		width: calc(50% - 40px);
	}

	.module__feature__category,
	.module__feature__date,
	.module__feature__date-time,
	.module__feature__location,
	.module__feature__title,
	.module__feature__text {
		padding-left: 30px;
	}

	.module__feature__category,
	.module__feature__location,
	.module__feature__title,
	.module__feature__text {
		padding-right: 30px;
	}

	.module__feature__category {
		padding-bottom: 5px;
	}

	.module__feature__date-time,
	.module__feature__location {
		color: $color-secondary;
		float: left;
		padding-top: 5px;
		text-transform: initial;
	}

	.module__feature__text {
		clear: left;
	}

	+	.module__feature__button {
		background-color: #fff;
		clear: right;
		float: right;
		margin: 0;
		padding: 30px;
		text-align: left;
		width: 50%;

		a {
			margin-left: 20px;
		}
	}
}

.module__feature__container__event__primary {
	background-color: #fff;
	border: 0;
	float: left;
	margin: 0;
	width: 50%;

	.module__feature__category {
		margin-top: 20px;
		padding-bottom: 5px;
		text-transform: uppercase;
	}

	.module__feature__text {
		padding-top: 15px;
	}

	.module__feature__button {
		float: right;
		margin: 20px 30px 30px;
	}
}


.module__feature__color {
	border-left-style: solid;
	border-left-width: 10px;
	height: calc(100% - 40px);
	position: absolute;
	top: 20px;
}

.module__feature__news {
	.module__feature__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.module__feature__container__event,
.module__feature__container__news {
	.module__feature__image {
		height: 200px;
		max-height: 200px;
	}
}

.module__feature__sponsors {
	.module__feature__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.module__feature__jobs {
	.module__feature__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.module__feature__container__news {
	background-color: #fff;
	margin: 0;
	position: relative;
	width: 33.3333%;

	.module__feature__image {
		display: block;
		flex-shrink: 0;
		height: 225px;
		width: 100%;
	}

	.module__feature__date,
	.module__feature__date-time,
	.module__feature__location {
		padding-top: 15px;
	}

	.module__feature__date-time,
	.module__feature__location {
		text-transform: initial;
	}

	.module__feature__title {
		padding-bottom: 50px;
	}

	.module__feature__button {
		bottom: 30px;
		position: absolute;
		right: 30px;
		text-align: right;
	}
}

.module__feature__container__sponsor,
.module__feature__container__job {
	align-items: center;
	background-color: #fff;
	display: flex;
	flex: 0 0 16.6666%;
	justify-content: center;
	max-width: 190px;

	.module__feature__image {
		background-color: #fff;
		max-height: 190px;
		max-width: 190px;
		width: 100%;
	}

	.module__feature__button {
		display: block;
		margin: 0;
		text-align: center;
		width: 100%;
	}
}


// scss-lint:disable ImportantRule
@media only screen and (max-width: $device-large) {
	.module__feature__container__event {
		.module__feature__button {
			margin-right: 25px;
		}
	}

	.module__feature__category,
	.module__feature__date,
	.module__feature__date-time,
	.module__feature__location,
	.module__feature__title,
	.module__feature__text {
		padding-left: 25px !important;
		padding-right: 25px !important;
	}
}

@media only screen and (max-width: $device-medium) {
	.module__feature__container__event {
		.module__feature__button {
			margin-right: 20px !important;
		}
	}

	.module__feature__category,
	.module__feature__date,
	.module__feature__date-time,
	.module__feature__location,
	.module__feature__title,
	.module__feature__text {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	.module__feature__location {
		clear: left;
	}
}

@media only screen and (max-width: $device-small) {
	.module__feature__container__event,
	.module__feature__container__news {
		margin: 0 !important;
		width: 100% !important;

		.module__feature__image {
			border-left: 0 !important;
			border-right: 0 !important;
		}
	}

	.module__feature__category,
	.module__feature__date-time,
	.module__feature__location,
	.module__feature__title,
	.module__feature__text {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}

	.module__feature__container__event__primary {
		.module__feature__category,
		.module__feature__date-time,
		.module__feature__location,
		.module__feature__title,
		.module__feature__text {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}

		.module__feature__image {
			border-bottom-width: 5px !important;
		}
	}

	.module__feature__sponsors .module__feature__container__sponsor,
	.module__feature__jobs .module__feature__container__job {
		flex: 0 0 33.3333%;
		max-width: 200px;
	}

	.module__feature__color {
		border-left-width: 5px;
	}

	.module__feature__container__event {
		.module__feature__button {
			clear: left;
			margin-left: 15px !important;
		}

		+ .module__feature__button {
			float: left;
			padding-left: 15px;
			width: 100%;

			a {
				margin-left: 0;
			}
		}
	}
}

@media only screen and (max-width: $device-xsmall) {
	.module__feature__container__news {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.module__feature__category,
	.module__feature__date,
	.module__feature__date-time,
	.module__feature__location,
	.module__feature__title,
	.module__feature__text {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.module__feature__container__event__primary {
		.module__feature__category,
		.module__feature__date-time,
		.module__feature__location,
		.module__feature__title,
		.module__feature__text {
			padding-left: 10px !important;
			padding-right: 10px !important;
		}

		.module__feature__image {
			border-bottom-width: 3px !important;
		}
	}

	.module__feature__color {
		border-left-width: 3px;
	}

	.module__feature__container__event {
		.module__feature__button {
			margin-left: 10px !important;
		}

		+ .module__feature__button {
			padding-left: 10px;
		}
	}

	.module__feature__container__news .module__feature__button {
		font-size: 14px;
		right: 15px;
	}
}
// scss-lint:enable ImportantRule
