.content__sponsor__container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.content__sponsor__container__sponsor {
	background-color: #fff;
	flex: 0 0 16.6666%;
	max-width: 190px;
}

.content__sponsor__image {
	max-height: 190px;
	max-width: 190px;
	width: 100%;
}


@media only screen and (max-width: $device-small) {
	.content__sponsor__container__sponsor {
		flex: 0 0 33.3333%;
		max-width: 200px;
	}
}
