@include font-face("Material-Design", $font-path);
@include font-face("Montserrat-Light", $font-path);
@include font-face("Montserrat-Regular", $font-path);

// Google's Material Design icons: http://www.flaticon.com/packs/material-design.
.icon:before,
.icon:after {
	display: inline-block;
	font-family: "Material-Design";
	font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	text-decoration: inherit;
	text-rendering: optimizeLegibility;
	text-transform: none;
}

.icon-price-tag:before {
	content: "\e900";
}

.icon-add-alarm-button:before {
	content: "\f100";
}

.icon-add-button-inside-black-circle:before {
	content: "\f101";
}

.icon-add-comment-button:before {
	content: "\f102";
}

.icon-add-label-button:before {
	content: "\f103";
}

.icon-add-plus-button:before {
	content: "\f104";
}

.icon-add-to-queue-button:before {
	content: "\f105";
}

.icon-add-user-button:before {
	content: "\f106";
}

.icon-adjust-button-with-plus-and-minus:before {
	content: "\f107";
}

.icon-airplane-mode-on-symbol:before {
	content: "\f108";
}

.icon-android:before {
	content: "\f109";
}

.icon-android-1:before {
	content: "\f10a";
}

.icon-apple-accessibility:before {
	content: "\f10b";
}

.icon-attachment-clip:before {
	content: "\f10c";
}

.icon-auto-white-balance:before {
	content: "\f10d";
}

.icon-automatic-brightness:before {
	content: "\f10e";
}

.icon-automatic-flash-symbol:before {
	content: "\f10f";
}

.icon-back-arrow:before {
	content: "\f110";
}

.icon-backspace-arrow:before {
	content: "\f111";
}

.icon-bed-quilt:before {
	content: "\f112";
}

.icon-been-here-marker:before {
	content: "\f113";
}

.icon-birthday-cake:before {
	content: "\f114";
}

.icon-black-bubble-speech:before {
	content: "\f115";
}

.icon-black-check-box:before {
	content: "\f116";
}

.icon-black-check-box-with-white-check:before {
	content: "\f117";
}

.icon-black-envelope-email-symbol:before {
	content: "\f118";
}

.icon-black-keyboard-with-white-keys:before {
	content: "\f119";
}

.icon-black-plane:before {
	content: "\f11a";
}

.icon-blank-check-box:before {
	content: "\f11b";
}

.icon-blank-frame:before {
	content: "\f11c";
}

.icon-blank-square:before {
	content: "\f11d";
}

.icon-blogger:before {
	content: "\f11e";
}

.icon-blueetooth:before {
	content: "\f11f";
}

.icon-bluetooth-connected:before {
	content: "\f120";
}

.icon-bluetooth-disabled-button:before {
	content: "\f121";
}

.icon-bluetooth-searching-signal-indicator:before {
	content: "\f122";
}

.icon-bluetooth-settings:before {
	content: "\f123";
}

.icon-bluetooth-signal-indicator:before {
	content: "\f124";
}

.icon-bookmark-button:before {
	content: "\f125";
}

.icon-bookmark-button-1:before {
	content: "\f126";
}

.icon-bookmark-outline:before {
	content: "\f127";
}

.icon-bookmark-ribbon:before {
	content: "\f128";
}

.icon-briefcase-download-button-with-downwards-arrow:before {
	content: "\f129";
}

.icon-briefcase-with-tick-inside:before {
	content: "\f12a";
}

.icon-brightness-control:before {
	content: "\f12b";
}

.icon-brochure-with-three-sections:before {
	content: "\f12c";
}

.icon-bubble-speech-with-three-lines:before {
	content: "\f12d";
}

.icon-bug-report-button:before {
	content: "\f12e";
}

.icon-burn-button:before {
	content: "\f12f";
}

.icon-button-on:before {
	content: "\f130";
}

.icon-call-forwarding:before {
	content: "\f131";
}

.icon-call-made-right-arrow:before {
	content: "\f132";
}

.icon-call-received-arrow:before {
	content: "\f133";
}

.icon-camera:before {
	content: "\f134";
}

.icon-camera-diaphragm:before {
	content: "\f135";
}

.icon-cancel-button:before {
	content: "\f136";
}

.icon-caps-lock-button:before {
	content: "\f137";
}

.icon-caps-lock-button-1:before {
	content: "\f138";
}

.icon-car-changing-lanes:before {
	content: "\f139";
}

.icon-car-directions:before {
	content: "\f13a";
}

.icon-car-front:before {
	content: "\f13b";
}

.icon-cell-phone-high-signal-indicator:before {
	content: "\f13c";
}

.icon-cell-phone-vibration:before {
	content: "\f13d";
}

.icon-cell-phone-with-blank-screen:before {
	content: "\f13e";
}

.icon-change-power-options:before {
	content: "\f13f";
}

.icon-chat-bubble:before {
	content: "\f140";
}

.icon-chat-bubbles:before {
	content: "\f141";
}

.icon-check-box:before {
	content: "\f142";
}

.icon-check-symbol:before {
	content: "\f143";
}

.icon-chemistry-polymer:before {
	content: "\f144";
}

.icon-circle-outline:before {
	content: "\f145";
}

.icon-circle-with-check-symbol:before {
	content: "\f146";
}

.icon-circles-extend-button:before {
	content: "\f147";
}

.icon-circumference:before {
	content: "\f148";
}

.icon-city-buildings-silhouette:before {
	content: "\f149";
}

.icon-clapperboard:before {
	content: "\f14a";
}

.icon-clapperboard-1:before {
	content: "\f14b";
}

.icon-clear-button:before {
	content: "\f14c";
}

.icon-clipboard-paste-button:before {
	content: "\f14d";
}

.icon-clock-with-white-face:before {
	content: "\f14e";
}

.icon-close-button:before {
	content: "\f14f";
}

.icon-closed-caption:before {
	content: "\f150";
}

.icon-cloud-backup-up-arrow:before {
	content: "\f151";
}

.icon-cloud-done-symbol:before {
	content: "\f152";
}

.icon-cloud-download:before {
	content: "\f153";
}

.icon-cloud-off:before {
	content: "\f154";
}

.icon-cloud-outline:before {
	content: "\f155";
}

.icon-cloud-symbol-inside-a-circle:before {
	content: "\f156";
}

.icon-compass-with-white-needles:before {
	content: "\f157";
}

.icon-connection-indicator:before {
	content: "\f158";
}

.icon-copy-content:before {
	content: "\f159";
}

.icon-create-group-button:before {
	content: "\f15a";
}

.icon-create-new-pencil-button:before {
	content: "\f15b";
}

.icon-credit-card:before {
	content: "\f15c";
}

.icon-crop-button:before {
	content: "\f15d";
}

.icon-crop-tool-button:before {
	content: "\f15e";
}

.icon-cut-content-button:before {
	content: "\f15f";
}

.icon-dark-cloud:before {
	content: "\f160";
}

.icon-developer-mode-for-smartphones-and-tablets:before {
	content: "\f161";
}

.icon-device-connected:before {
	content: "\f162";
}

.icon-device-connected-1:before {
	content: "\f163";
}

.icon-disc-is-full:before {
	content: "\f164";
}

.icon-do-not-disturb-rounded-sign:before {
	content: "\f165";
}

.icon-double-tick-indicator:before {
	content: "\f166";
}

.icon-download-button:before {
	content: "\f167";
}

.icon-download-button-1:before {
	content: "\f168";
}

.icon-download-to-smartphone:before {
	content: "\f169";
}

.icon-downwards-arrow-key:before {
	content: "\f16a";
}

.icon-drafts-evelope-button:before {
	content: "\f16b";
}

.icon-drop-down-arrow:before {
	content: "\f16c";
}

.icon-drop-down-round-button:before {
	content: "\f16d";
}

.icon-drop-up-arrow:before {
	content: "\f16e";
}

.icon-earth-grid-select-language-button:before {
	content: "\f16f";
}

.icon-ellipsis:before {
	content: "\f170";
}

.icon-email-inbox:before {
	content: "\f171";
}

.icon-emoticon-with-happy-face:before {
	content: "\f172";
}

.icon-end-call-button:before {
	content: "\f173";
}

.icon-enter-arrow:before {
	content: "\f174";
}

.icon-exit-to-app-button:before {
	content: "\f175";
}

.icon-expand-arrow:before {
	content: "\f176";
}

.icon-expand-button:before {
	content: "\f177";
}

.icon-facebook:before {
	content: "\f178";
}

.icon-failed-sms:before {
	content: "\f179";
}

.icon-fast-forward-button:before {
	content: "\f17a";
}

.icon-favorite-heart-button:before {
	content: "\f17b";
}

.icon-favorite-heart-outline-button:before {
	content: "\f17c";
}

.icon-filled-circle:before {
	content: "\f17d";
}

.icon-filled-cloud:before {
	content: "\f17e";
}

.icon-filled-speaker-with-white-details:before {
	content: "\f17f";
}

.icon-film-roll:before {
	content: "\f180";
}

.icon-filter-results-button:before,
.icon-filter-results-button:after {
	content: "\f181";
}

.icon-flash-off:before {
	content: "\f182";
}

.icon-flash-on-indicator:before {
	content: "\f183";
}

.icon-folder-symbol:before {
	content: "\f184";
}

.icon-forward-arrow:before {
	content: "\f185";
}

.icon-forward-right-arrow-button:before {
	content: "\f186";
}

.icon-framed-portrait:before {
	content: "\f187";
}

.icon-front-bus:before {
	content: "\f188";
}

.icon-front-car:before {
	content: "\f189";
}

.icon-front-store:before {
	content: "\f18a";
}

.icon-full-screen-exit:before {
	content: "\f18b";
}

.icon-gamepad:before {
	content: "\f18c";
}

.icon-gamepad-1:before {
	content: "\f18d";
}

.icon-get-directions-button:before {
	content: "\f18e";
}

.icon-gmail:before {
	content: "\f18f";
}

.icon-go-back-left-arrow:before {
	content: "\f190";
}

.icon-good-mood-emoticon:before {
	content: "\f191";
}

.icon-good-wifi-signal-with-three-bars:before {
	content: "\f192";
}

.icon-google-apps-script:before {
	content: "\f193";
}

.icon-google-cast:before {
	content: "\f194";
}

.icon-google-chrome-filled-with-white-colour:before {
	content: "\f195";
}

.icon-google-drive-document:before {
	content: "\f196";
}

.icon-google-drive-file:before {
	content: "\f197";
}

.icon-google-drive-folder-symbol:before {
	content: "\f198";
}

.icon-google-drive-fusion-tables:before {
	content: "\f199";
}

.icon-google-drive-image:before {
	content: "\f19a";
}

.icon-google-drive:before {
	content: "\f19b";
}

.icon-google-drive-pdf-file:before {
	content: "\f19c";
}

.icon-google-drive-presentation:before {
	content: "\f19d";
}

.icon-google-drive-spreadsheet:before {
	content: "\f19e";
}

.icon-google-glass:before {
	content: "\f19f";
}

.icon-google-hangouts:before {
	content: "\f1a0";
}

.icon-google-hangouts-turn-video-off:before {
	content: "\f1a1";
}

.icon-google-plus:before {
	content: "\f1a2";
}

.icon-google-plus-on-black-background:before {
	content: "\f1a3";
}

.icon-google-plus-party-mode:before {
	content: "\f1a4";
}

.icon-google-symbol:before {
	content: "\f1a5";
}

.icon-gps-fixed-indicator:before {
	content: "\f1a6";
}

.icon-gps-location-disabled:before {
	content: "\f1a7";
}

.icon-gps-location-symbol:before {
	content: "\f1a8";
}

.icon-gps-off:before {
	content: "\f1a9";
}

.icon-graduate-cap:before {
	content: "\f1aa";
}

.icon-half-filled-rating-star:before {
	content: "\f1ab";
}

.icon-hangouts-videocall-button:before {
	content: "\f1ac";
}

.icon-headset:before {
	content: "\f1ad";
}

.icon-headset-with-microphone:before {
	content: "\f1ae";
}

.icon-help-button-speech-bubble-with-question-mark:before {
	content: "\f1af";
}

.icon-help-round-button:before {
	content: "\f1b0";
}

.icon-hide-keyboard-button:before {
	content: "\f1b1";
}

.icon-high-quality-button:before {
	content: "\f1b2";
}

.icon-high-signal-indicator:before {
	content: "\f1b3";
}

.icon-high-wifi-signal-indicator:before {
	content: "\f1b4";
}

.icon-history-clock-button:before {
	content: "\f1b5";
}

.icon-home-button:before {
	content: "\f1b6";
}

.icon-horizontal-line-remove-button:before {
	content: "\f1b7";
}

.icon-hotel-with-three-floors:before {
	content: "\f1b8";
}

.icon-https-symbol:before {
	content: "\f1b9";
}

.icon-import-export-arrows:before {
	content: "\f1ba";
}

.icon-insert-picture-button:before {
	content: "\f1bb";
}

.icon-instagram:before {
	content: "\f1bc";
}

.icon-invert-colors-button:before {
	content: "\f1bd";
}

.icon-keyboard-left-arrow-button:before {
	content: "\f1be";
}

.icon-keyboard-right-arrow-button:before {
	content: "\f1bf";
}

.icon-keyboard-right-arrow-button-1:before {
	content: "\f1c0";
}

.icon-label-right-arrow-outline:before {
	content: "\f1c1";
}

.icon-landscape-with-mountains:before {
	content: "\f1c2";
}

.icon-laptop-outline:before {
	content: "\f1c3";
}

.icon-left-arrow-key:before {
	content: "\f1c4";
}

.icon-left-arrow-key-1:before {
	content: "\f1c5";
}

.icon-left-arrow-missed-call:before {
	content: "\f1c6";
}

.icon-light-bulb-on:before {
	content: "\f1c7";
}

.icon-link-button:before {
	content: "\f1c8";
}

.icon-linkedin:before {
	content: "\f1c9";
}

.icon-list-button-with-3-elements:before {
	content: "\f1ca";
}

.icon-list-of-three-elements-on-black-background:before {
	content: "\f1cb";
}

.icon-location-arrow:before {
	content: "\f1cc";
}

.icon-locked-padlock:before {
	content: "\f1cd";
}

.icon-locked-padlock-outline:before {
	content: "\f1ce";
}

.icon-low-signal-indicator:before {
	content: "\f1cf";
}

.icon-magic-wand-auto-fix-button:before {
	content: "\f1d0";
}

.icon-man-cycling:before {
	content: "\f1d1";
}

.icon-man-walking-directions-button:before {
	content: "\f1d2";
}

.icon-map-placeholder:before {
	content: "\f1d3";
}

.icon-map-symbol:before {
	content: "\f1d4";
}

.icon-mark-as-favorite-star:before {
	content: "\f1d5";
}

.icon-mark-as-unread-envelope-button:before {
	content: "\f1d6";
}

.icon-medium-brightness-cogwheel:before {
	content: "\f1d7";
}

.icon-medium-signal:before {
	content: "\f1d8";
}

.icon-medium-wifi-signal-with-two-bars:before {
	content: "\f1d9";
}

.icon-memory-chip:before {
	content: "\f1da";
}

.icon-menu-button:before {
	content: "\f1db";
}

.icon-merge-calls-arrow:before {
	content: "\f1dc";
}

.icon-microphone-of-voice:before {
	content: "\f1dd";
}

.icon-microphone-voice-button:before {
	content: "\f1de";
}

.icon-microsoft-excel:before {
	content: "\f1df";
}

.icon-microsoft-word:before {
	content: "\f1e0";
}

.icon-missed-call-phone-receiver-with-left-arrow:before {
	content: "\f1e1";
}

.icon-mountain-range:before {
	content: "\f1e2";
}

.icon-mountain-range-on-black-background:before {
	content: "\f1e3";
}

.icon-mountains-inside-a-circle:before {
	content: "\f1e4";
}

.icon-mouse-filled-tool:before {
	content: "\f1e5";
}

.icon-move-window:before {
	content: "\f1e6";
}

.icon-new-tab:before {
	content: "\f1e7";
}

.icon-new-tab-button:before {
	content: "\f1e8";
}

.icon-nfc-near-field-communication:before {
	content: "\f1e9";
}

.icon-notifications-bell-button:before {
	content: "\f1ea";
}

.icon-notifications-button:before {
	content: "\f1eb";
}

.icon-notifications-silenced:before {
	content: "\f1ec";
}

.icon-pages-of-google-plus:before {
	content: "\f1ed";
}

.icon-painter-palette:before {
	content: "\f1ee";
}

.icon-panoramic-mountain-photography:before {
	content: "\f1ef";
}

.icon-parking-sign:before {
	content: "\f1f0";
}

.icon-pause-button:before {
	content: "\f1f1";
}

.icon-phone-call-button:before {
	content: "\f1f2";
}

.icon-phone-locked:before {
	content: "\f1f3";
}

.icon-phone-paused-indicator:before {
	content: "\f1f4";
}

.icon-phone-receiver-with-bluetooth-sign:before {
	content: "\f1f5";
}

.icon-phone-settings:before {
	content: "\f1f6";
}

.icon-phone-working-indicator:before {
	content: "\f1f7";
}

.icon-photo-album:before {
	content: "\f1f8";
}

.icon-photo-frame:before {
	content: "\f1f9";
}

.icon-photo-library:before {
	content: "\f1fa";
}

.icon-photographic-film-roll:before {
	content: "\f1fb";
}

.icon-pinterest:before {
	content: "\f1fc";
}

.icon-planet-earth:before {
	content: "\f1fd";
}

.icon-play-arrow:before {
	content: "\f1fe";
}

.icon-play-button-inside-a-circle:before {
	content: "\f1ff";
}

.icon-play-next-button:before {
	content: "\f200";
}

.icon-play-rounded-button:before {
	content: "\f201";
}

.icon-play-slideshow-button:before {
	content: "\f202";
}

.icon-plus-one:before {
	content: "\f203";
}

.icon-poll-symbol-on-black-square-with-rounded-corners:before {
	content: "\f204";
}

.icon-power-connection-indicator:before {
	content: "\f205";
}

.icon-previous-track:before {
	content: "\f206";
}

.icon-printer-printing-document:before {
	content: "\f207";
}

.icon-problems-with-synchronization:before {
	content: "\f208";
}

.icon-progress-indicator:before {
	content: "\f209";
}

.icon-public-transport-subway:before {
	content: "\f20a";
}

.icon-public-transport-train-directions:before {
	content: "\f20b";
}

.icon-puzzle-piece-plugin:before {
	content: "\f20c";
}

.icon-radio-on-button:before {
	content: "\f20d";
}

.icon-random-line:before {
	content: "\f20e";
}

.icon-rate-star-button:before {
	content: "\f20f";
}

.icon-read-more-left-arrow-button:before {
	content: "\f210";
}

.icon-receipt:before {
	content: "\f211";
}

.icon-record-voice-microphone-button:before {
	content: "\f212";
}

.icon-refresh-button:before {
	content: "\f213";
}

.icon-refresh-button-1:before {
	content: "\f214";
}

.icon-reminder-bow:before {
	content: "\f215";
}

.icon-replay-arrow:before {
	content: "\f216";
}

.icon-reply-all-button:before {
	content: "\f217";
}

.icon-report-symbol:before {
	content: "\f218";
}

.icon-rewind-button:before {
	content: "\f219";
}

.icon-right-arrow-forward:before {
	content: "\f21a";
}

.icon-ring-volume-control-button:before {
	content: "\f21b";
}

.icon-rotate-to-left-button:before {
	content: "\f21c";
}

.icon-rotate-to-right-button:before {
	content: "\f21d";
}

.icon-round-account-button-with-user-inside:before {
	content: "\f21e";
}

.icon-round-add-button:before {
	content: "\f21f";
}

.icon-round-data-usage-symbol:before {
	content: "\f220";
}

.icon-round-delete-button:before {
	content: "\f221";
}

.icon-round-error-symbol:before {
	content: "\f222";
}

.icon-round-info-button:before {
	content: "\f223";
}

.icon-round-pause-button:before {
	content: "\f224";
}

.icon-round-remove-button:before {
	content: "\f225";
}

.icon-round-rgb-button:before {
	content: "\f226";
}

.icon-rounded-add-button:before {
	content: "\f227";
}

.icon-rounded-add-button-1:before {
	content: "\f228";
}

.icon-rounded-adjust-button-with-plus-and-minus:before {
	content: "\f229";
}

.icon-rounded-block-sign:before {
	content: "\f22a";
}

.icon-rounded-info-button:before {
	content: "\f22b";
}

.icon-rounded-pause-button:before {
	content: "\f22c";
}

.icon-rounded-remove-button:before {
	content: "\f22d";
}

.icon-rubbish-bin-delete-button:before {
	content: "\f22e";
}

.icon-save-button:before {
	content: "\f22f";
}

.icon-schedule-button:before {
	content: "\f230";
}

.icon-screen-locked:before {
	content: "\f231";
}

.icon-screen-locked-1:before {
	content: "\f232";
}

.icon-screen-rotation-button:before {
	content: "\f233";
}

.icon-screen-rotation-lock-button:before {
	content: "\f234";
}

.icon-screen-with-news-sections:before {
	content: "\f235";
}

.icon-screen-with-rounded-corners:before {
	content: "\f236";
}

.icon-sd-card:before {
	content: "\f237";
}

.icon-sd-card-1:before {
	content: "\f238";
}

.icon-searching-location-gps-indicator:before {
	content: "\f239";
}

.icon-searching-magnifying-glass:before {
	content: "\f23a";
}

.icon-select-all:before {
	content: "\f23b";
}

.icon-select-brightness-button:before {
	content: "\f23c";
}

.icon-send-button:before {
	content: "\f23d";
}

.icon-send-to-back-button:before {
	content: "\f23e";
}

.icon-send-to-front-button:before {
	content: "\f23f";
}

.icon-server-rack-with-three-levels:before {
	content: "\f240";
}

.icon-set-alarm:before {
	content: "\f241";
}

.icon-set-timer-button:before {
	content: "\f242";
}

.icon-settings-cogwheel-button:before {
	content: "\f243";
}

.icon-settings-cogwheel-inside-black-square:before {
	content: "\f244";
}

.icon-share-button:before {
	content: "\f245";
}

.icon-shared-folder:before {
	content: "\f246";
}

.icon-shining-rectangular-object:before {
	content: "\f247";
}

.icon-shining-sun:before {
	content: "\f248";
}

.icon-shopping-basket-button:before {
	content: "\f249";
}

.icon-shopping-cart:before {
	content: "\f24a";
}

.icon-show-apps-button:before {
	content: "\f24b";
}

.icon-show-less-fold-button:before {
	content: "\f24c";
}

.icon-show-menu-button:before {
	content: "\f24d";
}

.icon-show-more-button:before {
	content: "\f24e";
}

.icon-show-more-button-with-three-dots:before {
	content: "\f24f";
}

.icon-shuffle-mode-arrows:before {
	content: "\f250";
}

.icon-sim-card-problem:before {
	content: "\f251";
}

.icon-smartphone-dock:before {
	content: "\f252";
}

.icon-smartphone-with-three-buttons:before {
	content: "\f253";
}

.icon-sms-bubble-speech:before {
	content: "\f254";
}

.icon-sms-speech-bubble:before {
	content: "\f255";
}

.icon-snake-game:before {
	content: "\f256";
}

.icon-sort-button-with-three-lines:before {
	content: "\f257";
}

.icon-speech-bubble-with-mountains-inside:before {
	content: "\f258";
}

.icon-split-call-arrows:before {
	content: "\f259";
}

.icon-square-add-button:before {
	content: "\f25a";
}

.icon-stop-button:before {
	content: "\f25b";
}

.icon-swap-horizontal-orientation-arrows:before {
	content: "\f25c";
}

.icon-swap-vertical-orientation-arrows:before {
	content: "\f25d";
}

.icon-switch-camera-button:before {
	content: "\f25e";
}

.icon-switch-to-full-screen-button:before {
	content: "\f25f";
}

.icon-switch-vertical-orientation-arrows:before {
	content: "\f260";
}

.icon-switch-video-on:before {
	content: "\f261";
}

.icon-synchronization-arrows:before {
	content: "\f262";
}

.icon-synchronization-button-with-two-arrows:before {
	content: "\f263";
}

.icon-synchronization-problem:before {
	content: "\f264";
}

.icon-tab-symbol:before {
	content: "\f265";
}

.icon-tablet-with-blank-screen:before {
	content: "\f266";
}

.icon-tack-save-button:before {
	content: "\f267";
}

.icon-tag-button-with-happy-face:before {
	content: "\f268";
}

.icon-telephone-keypad-with-ten-keys:before {
	content: "\f269";
}

.icon-thermostat-temperature-wheel:before {
	content: "\f26a";
}

.icon-three-columns-layout:before {
	content: "\f26b";
}

.icon-three-dots-more-indicator:before {
	content: "\f26c";
}

.icon-thumb-down-button:before {
	content: "\f26d";
}

.icon-thumb-up-button:before {
	content: "\f26e";
}

.icon-tick-inside-circle:before {
	content: "\f26f";
}

.icon-time-lapse:before {
	content: "\f270";
}

.icon-traffic-light:before {
	content: "\f271";
}

.icon-train-public-transport:before {
	content: "\f272";
}

.icon-tumblr:before {
	content: "\f273";
}

.icon-turn-airplane-mode-off:before {
	content: "\f274";
}

.icon-turn-microphone-off-button:before {
	content: "\f275";
}

.icon-turn-notifications-off-button:before {
	content: "\f276";
}

.icon-turn-notifications-on-button:before {
	content: "\f277";
}

.icon-turn-synchronization-off:before {
	content: "\f278";
}

.icon-turn-video-off-button:before {
	content: "\f279";
}

.icon-turn-visibility-off-button:before {
	content: "\f27a";
}

.icon-twitter:before {
	content: "\f27b";
}

.icon-two-circles-one-inside-the-other:before {
	content: "\f27c";
}

.icon-two-men:before {
	content: "\f27d";
}

.icon-two-rows-and-three-columns-layout:before {
	content: "\f27e";
}

.icon-underline-button:before {
	content: "\f27f";
}

.icon-underline-text-button:before {
	content: "\f280";
}

.icon-undo-button:before {
	content: "\f281";
}

.icon-unlocked-padlock:before {
	content: "\f282";
}

.icon-up-arrow-key:before {
	content: "\f283";
}

.icon-upload-button:before {
	content: "\f284";
}

.icon-upload-to-cloud-button:before {
	content: "\f285";
}

.icon-usb-symbol:before {
	content: "\f286";
}

.icon-user-account-box:before {
	content: "\f287";
}

.icon-user-account-box-1:before {
	content: "\f288";
}

.icon-user-inside-bubble-speech:before {
	content: "\f289";
}

.icon-user-outline:before {
	content: "\f28a";
}

.icon-user-shape:before {
	content: "\f28b";
}

.icon-users-social-symbol:before {
	content: "\f28c";
}

.icon-verification-mark:before {
	content: "\f28d";
}

.icon-videocall-button:before {
	content: "\f28e";
}

.icon-videocam-filled-tool:before {
	content: "\f28f";
}

.icon-view-list-button:before {
	content: "\f290";
}

.icon-virtual-private-network-vpn-symbol:before {
	content: "\f291";
}

.icon-visibility-button:before {
	content: "\f292";
}

.icon-voice-message-microphone-button:before {
	content: "\f293";
}

.icon-voicemail:before {
	content: "\f294";
}

.icon-volume-down-indicator:before {
	content: "\f295";
}

.icon-volume-muted:before {
	content: "\f296";
}

.icon-volume-off-indicator:before {
	content: "\f297";
}

.icon-volume-up-indicator:before {
	content: "\f298";
}

.icon-warning-sign:before {
	content: "\f299";
}

.icon-watch-with-blank-face:before {
	content: "\f29a";
}

.icon-waving-flag:before {
	content: "\f29b";
}

.icon-web-server:before {
	content: "\f29c";
}

.icon-website-with-different-sections:before {
	content: "\f29d";
}

.icon-wifi-high-signal:before {
	content: "\f29e";
}

.icon-wifi-low-signal-with-one-bar:before {
	content: "\f29f";
}

.icon-wifi-signal-waves:before {
	content: "\f2a0";
}

.icon-window-with-different-sections:before {
	content: "\f2a1";
}

.icon-work-briefcase:before {
	content: "\f2a2";
}

.icon-workspace-with-two-horizontal-windows:before {
	content: "\f2a3";
}

.icon-world-shape-public-symbol:before {
	content: "\f2a4";
}

.icon-email:before {
	content: "\f2a5";
}

.icon-youtube:before {
	content: "\f2a6";
}
