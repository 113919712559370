// scss-lint:disable IdSelector
// scss-lint:disable ImportantRule
// scss-lint:disable SelectorFormat
// scss-lint:disable QualifyingElement
.tribe-events-pg-template {
	margin: 0 auto 60px !important;
	max-width: 1200px;
	padding: 0 30px;
	position: relative;
	text-align: left;
}

.events-archive {
	.module__feature__title {
		float: none;
	}

	.module__feature__container__event {
		float: left !important;
		margin-bottom: 10px;
		margin-top: 10px;
		position: relative;
		width: 100%;
	}

	.module__feature__title,
	.module__feature__date-time {
		margin-top: 0 !important;
		text-align: left;
	}

	.module__feature__date-time,
	.module__feature__location {
		float: left !important;
	}

	.module__feature__date-time {
		padding-bottom: 0 !important;
		padding-right: 0 !important;
	}

	.module__feature__image + .module__feature__category + .module__feature__title + .module__feature__date-time {
		padding-left: 0 !important;
	}

	.module__feature__location {
		padding-top: 5px !important;
	}

	.type-tribe_events {
		margin: 0;
		padding: 0;

		.module__feature__image {
			float: left;
			height: 110px;
			margin: 0 10px 0 20px;
			max-height: none;
			width: 110px;
		}

		.module__feature__container__event {
			float: none;
		}
	}

	#tribe-events-content-wrapper {
		max-width: none !important;
	}

	.tribe_events_filters_mobile_nav {
		display: none;
	}

	.tribe-events-user-recurrence-toggle {
		display: none;
	}

	#tribe-events-header {
		display: none;
	}

	.tribe-events-page-title {
		text-align: center;
		text-transform: uppercase;
	}

	#tribe-events-footer {
		border: 0;
		margin: 0;
		padding: 0;
	}

	.tribe-events-list-separator-month,
	.tribe-events-ical {
		display: none !important;
	}

	.module__feature__category {
		margin-top: 0 !important;
	}
}

.tribe-events-list-separator-month + .type-tribe_events.tribe-events-first {
	margin: 0;
	padding: 0;
}

.single-tribe_events article {
	.module__feature__title {
		float: left;
		margin-top: 30px;
		text-align: center;
		width: 100%;
	}

	.module__feature__date-time {
		display: block;
		margin: 0;
		text-align: center;
	}

	.social__links {
		float: left;
		list-style-type: none;
		margin: 10px 0 20px;
		text-align: center;
		width: 100%;
	}

	.module__feature__export {
		color: $color-secondary;
		display: table;
		margin: 0 auto 30px;
		text-align: center;
	}

	.module__map {
		width: 50%;
	}
}

.tribe-events-filters-vertical {
	float: left !important;
	margin: 40px 30px 0 0 !important;
	padding: 0 !important;
	width: 315px !important;

	+ #tribe-events-content {
		float: left !important;
		margin-top: 25px !important;
		padding: 0 !important;
		width: calc(100% - 345px) !important;
	}
}

.tribe-events-filters-group-heading {
	background-color: #265566 !important;
	border: 0 !important;
	color: #fff !important;
	font-size: 14px !important;
	margin: 0 !important;
	padding: 15px !important;
	text-transform: uppercase !important;

	&:after {
		border: 0 !important;
		color: #fff !important;
		content: "\f283" !important;
		float: right !important;
		font-family: "Material-Design" !important;
		margin-right: 20px !important;
		position: relative !important;
		right: auto !important;
		top: auto !important;
	}
}

.tribe-events-filters-content {
	background-color: #fff;
	border: 0;
}

.tribe_events_filter_item {
	background-color: #fff;
	margin-bottom: 20px;
	padding: 0 !important;

	&.closed {
		.tribe-events-filters-group-heading {
			background-color: #265566 !important;
		}

		.tribe-events-filters-group-heading:after {
			content: "\f16a" !important;
		}
	}

	.tribe-events-filters-group-heading {
		background-color: #466eb6 !important;
	}
}

.tribe-events-filter-group {
	border: 0;
	overflow: hidden;

	ul {
		max-height: 265px;
	}

	li {
		background: none;
		position: relative;

		&:hover {
			background: none;
		}
	}

	label {
		border: 0 !important;
		font-size: 16px;
	}
}

.tribe-events-filter-checkboxes input[type=checkbox] {
	background-color: #fff;
	border: 1px solid #b7b7b8 !important;
	cursor: pointer !important;
	display: inline-block !important;
	height: 25px !important;
	line-height: 25px !important;
	position: absolute !important;
	width: 25px !important;

	&:checked {
		color: $color-active !important;
		font-size: 16px !important;
		line-height: 25px !important;
		text-align: center !important;

		&:after {
			content: "\f143";
			font-family: "Material-Design";
			font-size: 16px;
			line-height: 25px;
			text-align: center;
		}
	}

	+ span {
		cursor: pointer !important;
		display: inline-block;
		font-size: 16px !important;
		line-height: 25px !important;
		padding-left: 35px !important;
		width: auto !important;
	}
}

#tribe-bar-search {
	color: $color-link !important;
	font-family: "Montserrat-Regular", serif;
	font-size: 16px !important;
	font-style: normal !important;
	height: 50px !important;
	padding: 0 25px !important;
	width: 100% !important;
}

#tribe_events_filters_wrapper input[type=submit] {
	@extend %button;
	display: inline-block !important;
	font-weight: normal;
	height: auto;
	margin-top: 40px;
	text-transform: none;
}

#tribe_events_filters_reset {
	display: inline-block !important;
	font-size: 16px !important;
}

// scss-lint:disable VendorPrefix
#tribe-bar-search::-webkit-input-placeholder {
	color: $color-link !important;
	font-style: normal;
}

#tribe-bar-search::-moz-placeholder {
	color: $color-link !important;
	font-style: normal;
}

#tribe-bar-search:-ms-input-placeholder {
	color: $color-link !important;
	font-style: normal;
}
// scss-lint:enable VendorPrefix

.tribe-toggle-child,
.tribe-filter-status {
	display: none !important;
}

.tribe-events-notices {
	background: none;
	border: 0;
	border-radius: 0;
	color: $color-secondary;
	padding: 30px !important;
	text-align: center;
	text-shadow: none;
}

#tribe-events-content.tribe-events-list {
	clear: none;
	margin-top: 10px;
}

.tribe-bar-disabled {
	display: none;
}

.tribe-mini-calendar-list-wrapper {
	display: none;
}

#tribe_events_filters_form {
	display: block !important;
}

.tribe-events-present {
	background-color: $color-active !important;
}

#tribe-events-bar {
	display: none !important;
}

// scss-lint:disable SelectorDepth
.datepicker {
	margin: 0 auto;
	padding: 0;
	width: 315px !important;

	.table-condensed {
		border-collapse: collapse !important;

		thead > tr:nth-child(2) {
			background-color: #265566 !important;
			color: #fff;
			font-family: "Montserrat-Light" !important;
			font-size: 14px !important;
			font-weight: normal;
			height: 44px;
			text-transform: uppercase;

			th {
				border-radius: 0 !important;

			}
		}

		thead > tr:nth-child(3) {
			font-family: "Montserrat-Light" !important;
			font-size: 14px;

			th {
				background-color: #898989 !important;
				border: 0;
				border-radius: 0;
				color: #fff;
				height: 45px;
				width: 50px;
			}
		}

		.next,
		.prev {
			cursor: pointer;
		}
	}

	.day {
		border: 0;
		color: #898989;
		font-size: 14px;
		height: 45px;
		line-height: 28px;

		&:hover,
		&.active,
		&:active:hover {
			background-color: $color-active !important;
			color: #fff !important;
		}
	}
}
// scss-lint:enable SelectorDepth

@media only screen and (max-width: $device-large) {
	.tribe-events-filters-vertical {
		display: block;
		height: auto !important;
		margin-top: 0 !important;
		width: 100% !important;
	}

	.tribe_events_filters_mobile_nav {
		display: block !important;
		height: 50px;
		width: 100%;

		ol {
			border: 0;
			float: left;
			margin: 0;
			padding: 0;
			text-align: center;
			width: 100%;
		}

		li {
			background-color: #265566;
			border: 0;
			color: #fff;
			cursor: pointer;
			display: inline-block;
			float: left;
			font-size: 24px;
			height: 50px;
			line-height: 50px;
			margin: 0;
			padding: 0;
			width: 25%;

			span {
				pointer-events: none;
			}
		}
	}

	.tribe-events-filters-content {
		margin-bottom: 40px;
		position: relative;
	}

	.tribe_events_filter_item {
		display: none;
		margin-bottom: 0;
		padding-top: 20px !important;
		width: 100%;
	}

	.tribe_events_filter_item--active {
		background-color: #466eb6 !important;
	}

	.tribe-events-filter-buttons,
	.tribe-events-filters-group-heading {
		display: none;
	}

	.tribe-events-filter-buttons {
		position: relative;
		z-index: 2;
	}

	.tribe-mini-calendar-wrapper {
		display: none;
	}

	#tribe-events-content.tribe-events-list {
		width: 100% !important;
	}

	.tribe-events-pg-template .module__map {
		width: 100% !important;
	}

	.tribe-events-pg-template {
		margin-bottom: 55px !important;
		padding: 0 25px !important;
	}

	.type-tribe_events {
		.module__feature__image {
			margin: 0 25px;
		}
	}

	.tribe-events-sub-nav li a {
		background-color: #fff;
		color: $color-primary;
		padding: 0;
	}

	.tribe-events-sub-nav .tribe-events-nav-left span,
	.tribe-events-sub-nav .tribe-events-nav-right span {
		display: inline;
		left: auto;
		position: relative;
		right: auto;
		top: auto;
	}

	.events-archive .type-tribe_events .module__feature__container__event {
		margin-left: 0;
	}
}

@media only screen and (max-width: $device-medium) {
	.tribe-events-pg-template {
		margin-bottom: 50px !important;
		padding: 0 20px !important;
	}

	.type-tribe_events {
		.module__feature__image {
			margin: 0 20px !important;
		}
	}

	.events-archive {
		.module__feature__container__event {
			margin-bottom: 10px !important;
			margin-top: 10px !important;
		}

		.module__feature__image {
			display: none;

			+ .module__feature__category + .module__feature__title + .module__feature__date-time {
				padding-left: 20px !important;
			}
		}

		.module__feature__date-time {
			padding-left: 20px !important;
			padding-right: 20px !important;
		}

		.module__feature__location {
			padding-left: 20px;
		}
	}
}

@media only screen and (max-width: $device-small) {
	.tribe-events-pg-template {
		margin-bottom: 45px !important;
		padding: 0 15px !important;
	}

	.type-tribe_events {
		.module__feature__image {
			margin: 0 15px !important;
		}

		.module__feature__date-time {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}

		.module__feature__location {
			clear: left;
			margin-left: 0;
		}
	}

	.events-archive {
		.module__feature__image {
			+ .module__feature__category + .module__feature__title + .module__feature__date-time {
				padding-left: 15px !important;
			}
		}
	}

	.module__feature__color {
		border-left-width: 5px;
	}
}

@media only screen and (max-width: $device-xsmall) {
	.tribe-events-pg-template {
		margin-bottom: 40px !important;
		padding: 0 10px !important;
	}

	.type-tribe_events {
		.module__feature__image {
			margin: 0 10px !important;

			+ .module__feature__category + .module__feature__title + .module__feature__date-time {
				padding-left: 10px !important;
			}
		}

		.module__feature__date-time {
			padding-left: 10px !important;
			padding-right: 10px !important;
		}
	}

	.module__feature__color {
		border-left-width: 3px;
	}
}
// scss-lint:enable IdSelector
// scss-lint:enable ImportantRule
// scss-lint:enable SelectorFormat
// scss-lint:enable QualifyingElement
