.module__image {
	float: left;
	width: 100%;
}

.module__image__image {
	display: block;
	width: 100%;
}

.col-4 .module__image__image {
	border-bottom: 0;
	border-top: 0;
}

.col-12 .module__image__image {
	border: 0;
}


@media only screen and (max-width: $device-small) {
	.col-3,
	.col-4,
	.col-6,
	.col-8,
	.col-9 {
		.module__image__image {
			max-height: 200px;
		}
	}

	.col-12 {
		.module__image__image {
			max-height: 300px;
		}
	}
}
