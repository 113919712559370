.module__form {
	background-color: #f3f3f3;
	float: left;
	width: 100%;
}

.nf-form-wrap {
	margin: 30px;
}

.nf-form-title,
.nf-form-fields-required,
.nf-form-hp {
	display: none;
}

.nf-field-label {
	color: $color-secondary;
	display: block;
	font: 16px/22px "Montserrat-Light";
	margin-bottom: 14px;
}

.nf-field-container {
	display: block;
	margin-bottom: 26px;

	input,
	select,
	textarea {
		background-color: #fff;
		border-radius: 5px;
		padding: 16px 20px;
	}

	input {
		height: 52px;
		width: 100%;
	}

	select {
		height: 52px;
		width: 100%;
	}

	textarea {
		min-height: 100px;
		resize: none;
	}

	.nf-error label {
		color: $color-primary;
	}
}

.nf-error div {
	color: $color-primary;
	margin-top: 10px;
}

.nf-error-msg {
	color: $color-primary;
}

.checkbox-container,
.listcheckbox-container {
	color: #fff;

	li {
		min-height: 40px;

		[type="checkbox"] {
			position: absolute;
		}
	}

	input {
		background-color: transparent;
		border: 2px solid #fff;
		border-radius: 0;
		height: 24px;
		margin-top: 8px;
		padding: 0;
		vertical-align: bottom;
		width: 24px;

		&.nf-checked {
			background-clip: content-box;
			background-color: #fff;
			box-shadow: inset 0 0 0 4px $color-primary;
			padding: 3px;
		}

		+ label {
			padding-left: 10px;
		}

		&[type="checkbox"] + label {
			display: block;
			padding-left: 35px;
			padding-top: 8px;
		}
	}
}

.listselect-container,
.liststate-container,
.listcountry-container {
	position: relative;

	label:before {
		background-color: #fff;
		bottom: 14px;
		color: $color-primary;
		content: "\f177";
		font-family: "Material-Design";
		padding-left: 20px;
		pointer-events: none;
		position: absolute;
		right: 20px;
		z-index: 2;
	}
}

.pikaday__container:before {
	background-color: #fff;
	bottom: 14px;
	color: $color-primary;
	content: "\f177";
	font-family: "Material-Design";
	padding-left: 20px;
	pointer-events: none;
	position: absolute;
	right: 20px;
	z-index: 2;
}

.listradio-container {
	color: #fff;

	li {
		height: 40px;
	}

	input {
		background-color: transparent;
		border: 2px solid #fff;
		border-radius: 100%;
		height: 24px;
		margin-top: 8px;
		padding: 0;
		vertical-align: bottom;
		width: 24px;

		&.nf-checked {
			background-clip: content-box;
			background-color: #fff;
			box-shadow: inset 0 0 0 4px $color-primary;
			padding: 3px;
		}

		+ label {
			padding-left: 10px;
		}
	}
}

.pikaday__container {
	display: block;
	width: 100%;
}

.textarea-container {
	textarea {
		padding: 10px;
		width: 100%;
	}
}

// scss-lint:disable SelectorFormat
.file_upload-container {
	.progress {
		height: 5px;
		margin-top: -20px;
	}

	.progress-bar {
		background-color: $color-primary;
	}

	.files_uploaded {
		p {
			color: #fff;
		}

		.delete {
			margin-left: 5px;
		}
	}
}
// scss-lint:enable SelectorFormat

.fileinput-button,
.submit-container input {
	@extend %button;
	background-color: $color-primary;
	color: #fff;
}


@media only screen and (max-width: $device-large) {
	.nf-form-wrap {
		@include container-spacing($device-large);
	}

	.button__primary {
		line-height: 20px;
	}
}

@media only screen and (max-width: $device-medium) {
	.nf-form-wrap {
		@include container-spacing($device-medium);
	}

	.button__primary {
		line-height: 19px;
	}
}

@media only screen and (max-width: $device-small) {
	.nf-form-wrap {
		@include container-spacing($device-small);
	}
}

@media only screen and (max-width: $device-xsmall) {
	.nf-form-wrap {
		@include container-spacing($device-xsmall);
	}
}
