.content__category-districts {
	background-color: #fff;
	border-bottom: 1px solid #f2f2f2;
	float: left;
	position: relative;
	width: 100%;

	&:last-of-type {
		border-bottom: 0;
	}

	.social__links {
		margin: 0;
	}
}

.content__category-districts__container {
	float: left;
	margin: 0 30px;
	width: calc(50% - 60px);
}

.content__category-districts__title {
	float: left;
	margin-top: 30px;
	width: 100%;
}

.content__category-districts__description {
	float: left;
	margin-top: 30px;
	width: 100%;
}

.content__category-districts__text {
	float: left;
	margin-bottom: 20px;
	margin-top: 20px;
	width: 100%;
}

.content__category-districts__url {
	clear: left;
	float: left;
	margin-bottom: 40px;
	margin-top: 20px;
	width: 100%;
}

.content__category-districts__button {
	clear: left;
	float: left;
	margin-bottom: 20px;
	text-align: left;
}

.content__category-districts__image {
	border-bottom-style: solid;
	border-bottom-width: 10px;
	float: left;
	margin-right: 30px;
	min-height: 340px;
	width: 100%;
}

.content__category-districts--featured {
	background-color: #eee;
}

.content__category-districts__representative {
	float: left;
	margin-top: 40px;
	width: 100%;
}

.content__category-districts__representative__info {
	float: left;
	margin-bottom: 0;
	margin-top: 30px;
	width: 100%;
}

// This is ok. It borrows the HTML of feature module's news type.
.single-districts .module__feature__container__news {
	margin: 0 10px;
	width: calc(33.3333% - 20px);

	.module__feature__type {
		margin-top: 10px;
		text-transform: uppercase;
	}

	.module__feature__button {
		position: relative;
		right: auto;
		text-align: left;
	}
}

.single-districts .module__map {
	width: 50%;
}


@media only screen and (max-width: $device-large) {
	.content__category-districts__container {
		@include container-spacing($device-large);
		margin-top: 0;
		width: calc(100% - 50px);
	}

	.content__category-districts__button {
		margin-top: 0;
	}

	.content__category-districts__image {
		margin-right: 25px;
	}

	.single-districts .module__map {
		width: 100%;
	}

	.module__map__container__info__directions {
		clear: left;
	}
}

@media only screen and (max-width: $device-medium) {
	.content__category-districts__container {
		@include container-spacing($device-medium);
		margin-top: 0;
		width: calc(100% - 40px);
	}

	.content__category-districts__text {
		margin-top: 15px;
	}

	.content__category-districts__image {
		margin-right: 20px;
	}
}

@media only screen and (max-width: $device-small) {
	.content__category-districts__container {
		@include container-spacing($device-small);
		margin-top: 0;
		width: calc(100% - 30px);
	}
}

@media only screen and (max-width: $device-xsmall) {
	.content__category-districts__container {
		@include container-spacing($device-xsmall);
		margin-top: 0;
		width: calc(100% - 20px);
	}

	.content__category-districts__description {
		margin-top: 20px;
	}

	.content__category-districts__image {
		height: 100%;
		margin: 15px auto 0;
	}
}
