.content__biography__header {
	clear: left;
	float: left;
	margin-top: 20px;
	padding: 20px 0;
	text-align: center;
	width: 100%;
}

.content__biography__container {
	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
	float: left;
	justify-content: center;
	width: 100%;
}

.content__biography__image {
	max-width: 285px;
	width: 100%;
}

.content__biography__name {
	margin-bottom: 10px;
	overflow: hidden;
	padding: 5px 0;
	text-align: center;
}

.content__biography__container__biography {
	flex: 0 0 calc(25% - 20px);
	margin: 10px;
	max-width: 285px;
}

.page-template-page-about--our-team--biography {
	.content__biography__detail {
		float: left;
		margin-top: 30px;
		width: 100%;

		.content__biography__image {
			float: left;
			max-width: 260px;
		}

		.content__biography__container {
			float: left;
			margin: 0 30px;
			max-width: 520px;
		}

		.content__biography__title,
		.content__biography__name,
		.content__biography__text {
			text-align: left;
			width: 100%;
		}

		.content__biography__name {
			max-height: none;
			overflow: visible;
		}

		.content__biography__title {
			margin-bottom: 20px;
		}

		.content__biography__text {
			margin-bottom: 40px;
		}
	}
}


@media only screen and (max-width: $device-large) {
	.content__biography__detail .content__biography__container {
		clear: left;
		margin: 0;
		max-width: 100%;
		width: auto;
	}

	.page-template-page-about--our-team--biography .content__biography__container {
		clear: left;
		margin: 20px 25px 0;
		max-width: none;
	}

	.content__biography__container__biography {
		flex: 0 0 calc(33.3333% - 20px);
		max-width: 320px;
	}

	.content__biography__image {
		max-width: 320px;
	}
}

@media only screen and (max-width: $device-medium) {
	// scss-lint:disable ImportantRule
	.page-template-page-about--our-team--biography .content__biography__container {
		margin-left: 20px !important;
		margin-right: 20px !important;
	}
	// scss-lint:enable ImportantRule
}

@media only screen and (max-width: $device-small) {
	// scss-lint:disable ImportantRule
	.page-template-page-about--our-team--biography .content__biography__container {
		margin-left: 15px !important;
		margin-right: 15px !important;
	}
	// scss-lint:enable ImportantRule

	.content__biography__container__biography {
		flex: 0 0 calc(50% - 20px);
		max-width: 389px;
	}

	.content__biography__image {
		max-width: 389px;
	}
}

@media only screen and (max-width: $device-xsmall) {
	// scss-lint:disable ImportantRule
	.page-template-page-about--our-team--biography .content__biography__container {
		margin-left: 10px !important;
		margin-right: 10px !important;
	}
	// scss-lint:enable ImportantRule
}
