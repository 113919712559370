.page__error__404 {
	text-align: center;

	h1 {
		color: $color-primary;
		font-size: 156px;
		line-height: 156px;
	}

	h2 {
		float: left;
		margin: 10px 0;
		width: 100%;
	}

	p {
		float: left;
		margin: 20px 0 10px;
		width: 100%;
	}

	li {
		margin: 10px 0;
	}
}
