// Usage: @include container-spacing($device-size);
@mixin container-spacing($device: null) {
	@if $device == $device-large {
		margin: 30px 25px 35px;
	} @else if $device == $device-medium {
		margin: 25px 20px 30px;
	} @else if $device == $device-small {
		margin: 20px 15px 25px;
	} @else if $device == $device-xsmall {
		margin: 20px 10px 25px;
	} @else {
		margin: 30px 30px 50px;
	}
}

// Usage: @include("tag-name", $devce-size);
@mixin font-size-and-line-height($tag: null, $device: null) {
	@if $tag == "h1" {
		// @if $device == $device-large {
		// 	font-size: 46px;
		// 	line-height: 53px;
		// } @else if $device == $device-medium {
		// 	font-size: 40px;
		// 	line-height: 46px;
		// } @else if $device == $device-xsmall {
		// 	font-size: 34px;
		// 	line-height: 39px;
		// } @else {
		// 	font-size: 52px;
		// 	line-height: 60px;
		// }

		font-size: 44px;
		line-height: 50px;
	} @else if $tag == "h2" {
	// 	@if $device == $device-large {
	// 		font-size: 32px;
	// 		line-height: 39px;
	// 	} @else if $device == $device-medium {
	// 		font-size: 28px;
	// 		line-height: 34px;
	// 	} @else if $device == $device-xsmall {
	// 		font-size: 24px;
	// 		line-height: 29px;
	// 	} @else {
	// 		font-size: 36px;
	// 		line-height: 44px;
	// 	}

		font-size: 28px;
		line-height: 34px;
	} @else if $tag == "h3" {
	// 	@if $device == $device-large {
	// 		font-size: 20px;
	// 		line-height: 26px;
	// 	} @else if $device == $device-medium {
	// 		font-size: 18px;
	// 		line-height: 24px;
	// 	} @else if $device == $device-xsmall {
	// 		font-size: 14px;
	// 		line-height: 18px;
	// 	} @else {
	// 		font-size: 24px;
	// 		line-height: 32px;
	// 	}

		font-size: 24px;
		line-height: 32px;
	} @else if $tag == "h4" or $tag == "h5" or $tag == "h6" {
	// 	@if $device == $device-large {
	// 		font-size: 13px;
	// 		line-height: 18px;
	// 	} @else if $device == $device-medium {
	// 		font-size: 12px;
	// 		line-height: 17px;
	// 	} @else if $device == $device-xsmall {
	// 		font-size: 12px;
	// 		line-height: 17px;
	// 	} @else {
	// 		font-size: 14px;
	// 		line-height: 19px;
	// 	}

		font-size: 14px;
		line-height: 19px;
	} @else if $tag == "p" {
	// 	@if $device == $device-large {
	// 		font-size: 15px;
	// 		line-height: 20px;
	// 	} @else if $device == $device-medium {
	// 		font-size: 14px;
	// 		line-height: 19px;
	// 	} @else if $device == $device-xsmall {
	// 		font-size: 13px;
	// 		line-height: 18px;
	// 	} @else {
	// 		font-size: 16px;
	// 		line-height: 21px;
	// 	}

		font-size: 16px;
		line-height: 21px;
	}
}

// Usage: @include font-face("fontName", "/path/fonts/fontName");
@mixin font-face($family, $font-path, $weight: normal, $style: normal) {
	@font-face{
		font-family: $family;
		src: url("#{$font-path}#{$family}.eot");
		src: url("#{$font-path}#{$family}.eot?#iefix") format("embedded-opentype"),
			url("#{$font-path}#{$family}.woff") format("woff"),
			url("#{$font-path}#{$family}.ttf") format("truetype"),
			url("#{$font-path}#{$family}.svg##{$family}") format("svg");
		font-style: $style;
		font-weight: $weight;
	}
}

// Usage: @include font-size(16);
@mixin font-size($value: 1.6) {
	font-size: $value + rem;
}

// Usage: @include visually-hidden();
@mixin visually-hidden() {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Usage: @include debug();
@mixin debug() {
	background-color: #ffc0cb;
	color: #f00;
}
