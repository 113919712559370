.module__page-title {
	background-color: #fff;
	float: left;
	width: 100%;
}

.module__page-title__title {
	margin: 20px 30px 30px;
	text-align: center;
}


@media only screen and (max-width: $device-large) {
	.module__page-title__title {
		margin-left: 25px;
		margin-right: 25px;
	}
}

@media only screen and (max-width: $device-medium) {
	.module__page-title__title {
		margin-left: 20px;
		margin-right: 20px;
	}
}

@media only screen and (max-width: $device-small) {
	.module__page-title__title {
		margin-left: 15px;
		margin-right: 15px;
	}
}

@media only screen and (max-width: $device-xsmall) {
	.module__page-title__title {
		margin-left: 10px;
		margin-right: 10px;
	}
}
