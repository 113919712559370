// Font path.
$font-path: "../../fonts/";

// Image path.
$image-path: "../../images/build/";

// Colors.
$color-primary: #e84336;
$color-secondary: #656767;

$color-link: $color-primary;
$color-visited: #a72d5c;
$color-hover: #bf2126;
$color-active: #a92a5b;
$color-focus: #e84336;

// Device sizes:
$device-xlarge: 1200px;
$device-large: 960px;
$device-medium: 768px;
$device-small: 640px;
$device-xsmall: 480px;
