.site__navigation__more {
	display: inline-block;
	float: right;

	ul {
		height: 105px;
		margin: 10px 0 0;
		width: 100%;
	}

	li {
		display: inline-block;
		float: right;
		line-height: 105px;
		margin: 0 20px;
		padding: 0;
		vertical-align: middle;

		&:first-child {
			margin-right: 0;
		}
	}
}


@media only screen and (max-width: $device-large) {
	.site__navigation__more {
		float: none;
		width: 100%;

		ul {
			display: block;
			height: auto;
			margin-bottom: 20px;
			margin-top: 20px;
			text-align: center;
		}

		li {
			display: block;
			float: none;
			line-height: 30px;

			&:first-child {
				margin-right: 20px;
			}
		}
	}
}
