.site__legal__and__terms {
	background-color: #fff;
	float: left;
	position: relative;
	text-align: center;
	width: 100%;

	&:before,
	&:after {
		background-color: inherit;
		content: "";
		height: 300px;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&:before {
		left: -100%;
	}

	&:after {
		left: 100%;
	}

	p {
		margin: 0 0 20px;

		&:first-child {
			margin-bottom: 0;
			margin-top: 36px;
		}
	}
}


@media only screen and (max-width: $device-large) {
	.site__legal__and__terms a {
		@include font-size-and-line-height("p", $device-large);
	}
}

@media only screen and (max-width: $device-medium) {
	.site__legal__and__terms a {
		@include font-size-and-line-height("p", $device-medium);
	}
}

@media only screen and (max-width: $device-xsmall) {
	.site__legal__and__terms a {
		@include font-size-and-line-height("p", $device-xsmall);
	}
}
