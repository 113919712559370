.module__map {
	float: left;
	width: 100%;
}

.module__map__container {
	height: 400px;
	margin-bottom: 20px;
	margin-top: 20px;
	position: relative;
	width: 100%;
}

.module__map__container__map {
	height: 100%;
	width: 100%;
}

.module__map__container__info {
	background-color: rgba(255, 255, 255, .85);
	height: 340px;
	margin: 30px;
	min-width: 300px;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	width: 35%;
}

.module__map__container__info__location {
	margin-bottom: 10px;
}

.module__map__container__info__text {
	left: 30px;
	opacity: 1;
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
}

.module__map__container__info__phone {
	margin-top: 10px;
}

.module__map__container__info__phone,
.module__map__container__info__email {
	color: $color-primary;
}

.module__map__container__info__email {
	margin-bottom: 10px;
	margin-top: 0;
}

.module__map__container__info__directions {
	margin: 20px 0;
}

.module__map__container__nav {
	bottom: 15px;
	left: 30px;
	position: absolute;
	width: calc(100% - 60px);
	z-index: 2;

	img {
		height: 40px;
	}

	.module__map__container__nav__left-arrow {
		display: inline-block;
		margin-right: 15px;
	}

	.module__map__container__nav__count {
		bottom: 15px;
		color: $color-secondary;
		display: inline-block;
		font-family: "Montserrat-Light";
		font-size: 18px;
		line-height: 24px;
	}

	.module__map__container__nav__right-arrow {
		display: inline-block;
		margin-left: 15px;
	}
}


@media only screen and (max-width: $device-large) {
	.module__map__container {
		height: 400px;
		position: relative;
		width: 100%;
	}

	.module__map__container__map {
		height: 188px;
		width: 100%;
	}

	.module__map__container__info {
		background-color: #fff;
		height: auto;
		margin: 0;
		padding: 20px 25px 25px;
		position: relative;
		width: 100%;
	}

	.module__map__container__info__text {
		left: 0;
		position: relative;
		right: 0;
		top: 0;
		transform: none;
		width: 100%;
	}

	.module__map__container__info__text--title {
		margin-bottom: 20px;
		width: 100%;
	}

	.module__map__container__info__address {
		float: left;
		margin-bottom: 20px;
		width: 50%;
	}

	.module__map__container__info__phone,
	.module__map__container__info__email {
		color: $color-primary;
		float: left;
		width: 50%;
	}

	.module__map__container__info__phone {
		margin-top: 0;
	}

	.module__map__container__info__email {
		margin-bottom: 20px;
	}

	.module__map__container__info__directions {
		float: left;
		margin: 0;
	}

	.module__map__container__nav {
		float: left;
		left: 0;
		margin-top: 40px;
		position: relative;
		width: 100%;

		.module__map__container__nav__left-arrow {
			margin-right: 10px;
		}

		.module__map__container__nav__right-arrow {
			margin-left: 10px;
		}

		.module__map__container__nav__count {
			font-size: 14px;
			line-height: 18px;
		}
	}
}

@media only screen and (max-width: $device-medium) {
	.module__map__container__info {
		padding: 20px;
	}
}

@media only screen and (max-width: $device-small) {
	.module__map__container__info {
		padding: 15px;
	}
}

@media only screen and (max-width: $device-xsmall) {
	.module__map__container__info__address {
		width: 100%;
	}

	.module__map__container__info__phone,
	.module__map__container__info__email,
	.module__map__container__info__directions {
		float: left;
		width: 100%;
	}

	.module__map__container__info__phone {
		margin-top: 0;
	}

	.module__map__container__info {
		height: auto;
		padding: 10px;
	}
}
